import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import StorageProviderWeb from "../../../framework/src/StorageProvider.web";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    candidate_name: string,
    company_name: string,
    position: string,
    recruiter: string,
    select_state: string,
    select_city: string,
    submission_date: string,
    interview_date: string,
    category: string,
    clearance_level: string,
    token: string,
    positionArr: { value: string }[],
    errorOpenNotification: boolean,
    isError: boolean,
    messageText: string
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class CvresumeCandidateManagement2Controller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    addCandidateDetails: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            candidate_name: '',
            company_name: '',
            position: "",
            recruiter: '',
            select_state: '',
            select_city: '',
            submission_date: '',
            interview_date: '',
            category: '',
            clearance_level: '',
            token: '',
            positionArr: [
                {
                    value: 'Mechanical Engineer',
                },
                {
                    value: 'Civil Engineer',
                },
                {
                    value: 'Electrical Engineer',
                },
                {
                    value: 'Software Engineer',
                },
                {
                    value: 'Software Developer',
                },
            ],
            errorOpenNotification: false,
            isError: false,
            messageText: ''
            // Customizable Area End
        };

        // Customizable Area Start
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    async componentDidMount() {

        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));

        let token = await this.getStorageProvider()
        this.setUpdateToken(token);

    }

    setUpdateToken = (token: string) => {
        this.setState({ token: token });
    }

    getStorageProvider = async () => {
        return StorageProviderWeb.get("token");
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId != null) {
            /* istanbul ignore next */
            if (apiRequestCallId === this.addCandidateDetails) {
                if (!responseJson.errors && responseJson) {
                    //On Add Candidate Success
                    /* istanbul ignore next */
                    this.setState({ isError: false, errorOpenNotification: true, messageText: 'Candidate added successfully' })
                    setTimeout(() => {
                        this.setState({ errorOpenNotification: false })
                    }, 2000);
                } else {
                    //Check Error Response
                    /* istanbul ignore next */
                    this.setState({ isError: true, errorOpenNotification: true, messageText: responseJson?.meta?.message || 'Candidate not added.' })
                    setTimeout(() => {
                        this.setState({ errorOpenNotification: false })
                    }, 2000);
                }
            }

        }

    }
    // web events
    finalErrorMessage(error: string, touch: string) {
        if (error && touch) {
            return <div style={{
                color: "red",
                fontSize: "14px",
                margin: "5px 5px 5px 0px"
            }}>
                {error}
            </div>
        }
    }
    // Customizable Area Start
    addNewCandidate = async (values: {
        candidate_name: string,
        company_name: string,
        position: string,
        recruiter: string,
        select_state: string,
        select_city: string,
        submission_date: string,
        interview_date: string,
        category: string,
        clearance_level: string
    }) => {
        const header = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const payload = {
            company_name: values.company_name,
            candidate_name: values.candidate_name,
            state: values.select_state,
            city: values.select_city,
            position: values.position,
            submission_date: values.submission_date,
            interview_date: values.interview_date,
            recruiter: values.recruiter,
            category: values.category,
            clearence_level: values.clearance_level,

        };

        const httpBody = {
            candidate: payload
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addCandidateDetails = requestMessage.messageId;


        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/candidates/candidates`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}