Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.httpPostMethod = 'POST'
exports.httpContentType = 'application/json'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.getFinalSelectedListApiEndPoint = 'bx_block_advanced_search/display';
exports.errorTitle = 'Error'
exports.defaultUrlForFilter = "bx_block_advanced_search/get_filter"
exports.EndPointCompanyList = "bx_block_advanced_search/search"
exports.EndPointConfirmList = "bx_block_advanced_search/confirm"
// Customizable Area End
