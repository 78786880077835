import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { ApiCall } from "../../../../components/src/ApiCall.web";
import flatpickr from 'flatpickr';
import moment from "moment";

export const configJSON = require("./../config.js");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start

  candidateDates: any;
  jobType: any;
  clearanceLevel: any;
  locationNew: any;
  statenew: any;
  activeStatus: any;
  expernece: any;
  filtredlistID: any;
  filteredCandidateList: any;
  candidateList: any;
  selectedUserId: any;
  Technical: any;
  // Executive:string;
  jobtype: any;
  ischeked: any
  selectedCandidate: any
  checkboxItems: any,
  colorChnage: boolean
  // Customizable Area End
}
interface SS { }

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCandiadteCallId: any;
  addDateTimeCallId: any;
  filtredItemslistID: any;
  datePicker: any;
  userId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      candidateList: [1],
      selectedUserId: null,
      candidateDates: {
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      },
      jobType: [],
      clearanceLevel: [],
      expernece: [],
      locationNew: "",
      statenew: "",
      activeStatus: [],
      filtredlistID: "",
      filteredCandidateList: [],
      Technical: 'Technical',
      selectedCandidate: [],
      colorChnage: false,

      jobtype: {
        publicjtrust: false
      },
      ischeked: {
        managment: false,
        technichal: false,
        admisntrative: false,
        excative: false,
        intelligence: false,
        publictruts: false,
        topsecreat: false,
        secreat: false,
        tsSci: false,
        tsScipoly: false,
        tsScifcp: false,
        undefined: false,
        ontwoyear: false,
        threetofounryear: false,
        fievtosixyera: false,
        nintydays: false,
        oneeightdaya: false,
        threesixtydaya: false,
        threesixtyplus: false,
      },

      checkboxItems: [

      ],
    };
    this.datePicker = {};
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.modlfySearch()
  }

  async componentWillUnmount() {
    if (this.datePicker) {
      this.datePicker.destroy();
    }
  }

  async receive(_from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      // Customizable Area Start
      const id = await apiRequestCallId
      const candidateID = await this.getCandiadteCallId
      const candidateIDFilter = await this.filtredItemslistID
      const getcandidatcalneder = await this.addDateTimeCallId

      if (id == getcandidatcalneder) {
        if (this.state.jobType.length > 0 || this.state.expernece.length > 0 || this.state.clearanceLevel.length > 0 || this.state.activeStatus.length > 0) {
          setTimeout(() => {
            this.modlfySearch()
          }, 1000);
        } else {
          this.modlfySearch()
        }
      }
      if (id == candidateID) {
        if (responseJson) {
          this.setState({ candidateList: responseJson.active_candidates }, () => {
            this.controlPicker();
          });
        }
      }
      if (id == candidateIDFilter) {
        this.setState({ candidateList: [] })
        this.setState({ candidateList: responseJson?.active_candidates }, () => {
          this.controlPicker();
        });
      }
      // Customizable Area End
    }
  }
  // Customizable Area Start
  handleDatePicker = (Id: any) => {
    this.userId = Id;
  }

  controlPicker = () => {
    this.state.candidateList.forEach((candidate: any) => {
      /* istanbul ignore next */
      this.datePicker[candidate.id] = flatpickr(`#datepicker-${candidate.id}`, {
        enableTime: true,
        mode: 'range',
        dateFormat: 'd-m-Y h:i K',
        minDate: 'today',
        position: "auto center",
        onOpen: (_selectedDates, _dateStr, instance) => this.handleOnOpenDatePicker(instance),
        onClose: (_selectedDates, dateStr, _instance) => this.handleOnCloseDatePicker(dateStr, candidate.id),
      })
    });
  }

  handleOnOpenDatePicker = (instance: any) => {
    const hourInput: any = instance.calendarContainer.querySelector('.flatpickr-hour');
    const minuteInput: any = instance.calendarContainer.querySelector('.flatpickr-minute');

    hourInput.addEventListener('input', this.handleHourInput);
    minuteInput.addEventListener('input', this.handleMinuteInput);
  }

  handleOnCloseDatePicker = (dateStr: any, id: any) => {
    console.log("@@__ID", id);
    if (dateStr) {
      const [startDateTime, endDateTime] = dateStr.split(' to ');
      this.setState({
        candidateDates: {
          startDate: startDateTime.split(' ')[0],
          startTime: `${startDateTime.split(' ')[1]} ${startDateTime.split(' ')[2]}`,
          endDate: endDateTime.split(' ')[0],
          endTime: `${endDateTime.split(' ')[1]} ${endDateTime.split(' ')[2]}`,
        }
      }, () => {
        this.handleDateTime(id);
      });
    }
  }

  handleHourInput = (e: any) => {
    const value = parseInt(e.target.value);
    if (value > 12) {
      e.target.value = 12;
    }
  }

  handleMinuteInput = (e: any) => {
    const value = parseInt(e.target.value);
    if (value > 60) {
      e.target.value = 60;
    }
  }

  formatDate = (date: any) => {
    const regexFormats = [
      'DD/MM/YYYY',
      'MM/DD/YYYY',
      'YYYY-MM-DD',
      'DD-MM-YYYY',
      'YYYY/MM/DD',
      'MM-DD-YYYY',
    ];

    let formattedDate = moment(date, regexFormats, true).format('MM/DD/YYYY');

    if (!moment(formattedDate, 'MM/DD/YYYY', true).isValid()) {
      formattedDate = date;
    }

    return formattedDate;
  };

  getCandidateList = () => {
    const token = localStorage.getItem("token");
    this.getCandiadteCallId = ApiCall({
      contentType: configJSON.dashboarContentType,
      token: token,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.getCandidateList,
    });
  }

  handleDateTime = (usrId: any) => {
    const userToken = localStorage.getItem("token");
    const { startDate, endDate, startTime } = this.state.candidateDates;

    const payload = {
      "candidate": {
        "interview_date": endDate,
        "submission_date": startDate,
        "time": startTime
      }
    };
    this.addDateTimeCallId = ApiCall({
      contentType: configJSON.dashboarContentType,
      token: userToken,
      method: "PUT",
      endPoint: configJSON.addDateTimeApiUrl + `${usrId}`,
      body: payload,
    });
  }


  selectAllCandidates = () => {

    if (this.state.selectedCandidate.length < 0 || this.state.colorChnage) {
      this.setState({
        selectedCandidate: [], colorChnage: !this.state.colorChnage
      })
    } else {

      const allCandidateIDs = this.state.candidateList.map((candidate: any) => candidate.id);

      this.setState({
        selectedCandidate: allCandidateIDs, colorChnage: !this.state.colorChnage
      });
    }
  }

  selctedThisCandidate = (candidateID: any) => {
    /* istanbul ignore next */
    if (this.state.selectedCandidate.includes(candidateID)) {
      const index = this.state.selectedCandidate.findIndex((i: any) => i === candidateID)
      this.state.selectedCandidate.splice(index, 1)
      this.setState({
        selectedCandidate: this.state.selectedCandidate,
      })
    }
    else {
      this.setState({ selectedCandidate: [candidateID, ...this.state.selectedCandidate] })
    }
  }

  handleUncheck = (e: any) => {
    const ischeked = { intelligence: false, managment: false, excative: false }
    this.setState({ ischeked: ischeked, jobType: [], expernece: [], clearanceLevel: [], activeStatus: [], locationNew: "", selectedCandidate: [], statenew: "" })
    /* istanbul ignore next */
    setTimeout(() => {
      this.modlfySearch()
    }, 100);
  };

  handleCheckboxChangejobType = (value: string) => {
    switch (value) {
      case "Management":
        this.setState({ ischeked: { ...this.state.ischeked, managment: !this.state.ischeked.managment } })
        break
      case "Technical":
        this.setState({ ischeked: { ...this.state.ischeked, technichal: !this.state.ischeked.technichal } })
        break
      case "Administrative":
        this.setState({ ischeked: { ...this.state.ischeked, admisntrative: !this.state.ischeked.admisntrative } })
        break
      case "Intelligence":
        this.setState({ ischeked: { ...this.state.ischeked, intelligence: !this.state.ischeked.intelligence } })
        break
      case "Executive":
        this.setState({ ischeked: { ...this.state.ischeked, excative: !this.state.ischeked.excative } })
        break
    }
    if (this.state.jobType.includes(value)) {

      const index = this.state.jobType.findIndex((i: any) => i === value)
      this.state.jobType.splice(index, 1)
      this.setState({
        jobType: this.state.jobType,
      });
    } else {
      this.setState({ jobType: [...this.state.jobType, value] });
    }
    setTimeout(() => {
      /* istanbul ignore next */
      this.modlfySearch()
    }, 2000);
  }

  handleCheckboxChangeclearanceLevel = (value: string) => {
    switch (value) {
      case "Public Trust":
        this.setState({ ischeked: { ...this.state.ischeked, publictruts: !this.state.ischeked.publictruts } })
        break
      case "Secret":
        this.setState({ ischeked: { ...this.state.ischeked, secreat: !this.state.ischeked.secreat } })
        break
      case "Top secret":
        this.setState({ ischeked: { ...this.state.ischeked, topsecreat: !this.state.ischeked.topsecreat } })
        break
      case "TS/SCI":
        this.setState({ ischeked: { ...this.state.ischeked, tsSci: !this.state.ischeked.tsSci } })
        break
      case "TS/SCI CI Poly":
        this.setState({ ischeked: { ...this.state.ischeked, tsScipoly: !this.state.ischeked.tsScipoly } })
        break
      case "TS/SCI FSP":
        this.setState({ ischeked: { ...this.state.ischeked, tsScifcp: !this.state.ischeked.tsScifcp } })
        break
      case "Undefined":
        this.setState({ ischeked: { ...this.state.ischeked, undefined: !this.state.ischeked.undefined } })
        break
    }
    if (this.state.clearanceLevel.includes(value)) {
      const index = this.state.clearanceLevel.findIndex((i: any) => i === value)
      this.state.clearanceLevel.splice(index, 1)
      this.setState({
        clearanceLevel: this.state.clearanceLevel,
      });
    } else {
      this.setState({ clearanceLevel: [...this.state.clearanceLevel, value] });
    }
    /* istanbul ignore next */
    setTimeout(() => {
      this.modlfySearch()
    }, 1000);
  }

  handleCheckboxChangeexpernece = (value: string) => {
    switch (value) {
      case "1,2":
        this.setState({ ischeked: { ...this.state.ischeked, ontwoyear: !this.state.ischeked.ontwoyear } })
        break
      case "3,4":
        this.setState({ ischeked: { ...this.state.ischeked, threetofounryear: !this.state.ischeked.threetofounryear } })
        break
      case "5,6":
        this.setState({ ischeked: { ...this.state.ischeked, fievtosixyera: !this.state.ischeked.fievtosixyera } })
        break

    }
    if (this.state.expernece.includes(value)) {

      const index = this.state.expernece.findIndex((i: any) => i === value)
      this.state.expernece.splice(index, 1)
      this.setState({
        expernece: this.state.expernece,
      });
    } else {

      this.setState({ expernece: [...this.state.expernece, value] });
    }
    /* istanbul ignore next */
    setTimeout(() => {
      this.modlfySearch()
    }, 1000);
  }

  handleCheckboxChangeactiveStatus = (value: string) => {
    switch (value) {
      case "1,90":
        this.setState({ ischeked: { ...this.state.ischeked, nintydays: !this.state.ischeked.nintydays } })
        break
      case "91,180":
        this.setState({ ischeked: { ...this.state.ischeked, oneeightdaya: !this.state.ischeked.oneeightdaya } })
        break
      case "181,365":
        this.setState({ ischeked: { ...this.state.ischeked, threesixtydaya: !this.state.ischeked.threesixtydaya } })
        break
      case "366":
        this.setState({ ischeked: { ...this.state.ischeked, threesixtyplus: !this.state.ischeked.threesixtyplus } })
        break
    }
    if (this.state.activeStatus.includes(value)) {

      const index = this.state.activeStatus.findIndex((i: any) => i === value)
      this.state.activeStatus.splice(index, 1)
      this.setState({
        activeStatus: this.state.activeStatus,
      });
    } else {

      this.setState({ activeStatus: [...this.state.activeStatus, value] });
    }
    /* istanbul ignore next */
    setTimeout(() => {
      this.modlfySearch()
    }, 1000);
  }

  handleCheckboxChangelocation = (value: string) => {
    this.setState({
      locationNew: value
    });/* istanbul ignore next */
    setTimeout(() => {
      this.modlfySearch()
    }, 1000);
  }

  handleCheckboxChangelocationState = (value: string) => {
    this.setState({
      statenew: value
    });/* istanbul ignore next */
    setTimeout(() => {
      this.modlfySearch()
    }, 1000);
  }

  modlfySearch = () => {
    const userToken = localStorage.getItem("token");
    this.filtredItemslistID = ApiCall({
      contentType: configJSON.dashboarContentType,
      token: userToken,
      method: "GET",
      endPoint: `account_block/candidates/get_filtered_candidates?job_type=${[...this.state.jobType]}&clearance=${[...this.state.clearanceLevel]}&experience[]=${[...this.state.expernece]}&city=${this.state.locationNew}&state=${this.state.statenew}&active_from[]=${[...this.state.activeStatus]}`
    });
  };
  // Customizable Area End
}
