import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  TextField, Typography,
  InputLabel,
  InputBase,
  MenuItem,
  Button,
  CircularProgress,
  Card, Select,
  Snackbar,
} from "@material-ui/core";
import * as Yup from 'yup'
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import HeaderWeb from "../../../components/src/Header.web";
import { Formik } from 'formik';
export const configJSON = require("./config");


const priority_level = [
  {
    value: 'Strong submission',
  },
  {
    value: 'Urgent',
  },
  {
    value: 'Caution',
  },

];
const currencies = [
  {
    value: 'TS/SCI FSP ',
  },
  {
    value: 'Secret',
  },
  {
    value: 'Top secret',
  },
  {
    value: 'Public Trust',
  },
  {
    value: 'TS/SCI',
  },
  {
    value: 'TS/SCI CI Poly',
  },

];
const state = [
  {
    value: 'Bihar',
  },
  {
    value: 'Rajsthan',
  },

  {
    value: 'Maharastra',
  },
  {
    value: 'Gujarat',
  },
];
const city = [
  {
    value: 'Ahemdabad',
  },
  {
    value: 'Morbi',
  },
  {
    value: 'Surat',
  },
  {
    value: 'Rajkot',
  },

];

// Customizable Area End

import PostCreationBlockControllerWeb, {
  Props
} from "./PostCreationBlockController.web";

export default class PostCreationBasicBlockWeb extends PostCreationBlockControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End


  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const postValidationSchema: any = Yup.object().shape({
      company_name: Yup.string()
        .required('Company name is required')
        .matches(
          /[a-zA-Z]/,
          'Insert at least one character'
        ),
      position: Yup.string()
        .required('Position is required')
        .matches(
          /[a-zA-Z]/,
          'Insert at least one character'
        ),
      clearance: Yup.string().required('Clearance is required'),
      priority_level: Yup.string().required('Priority level is required'),
      min_salary: Yup.string().required('Min Salary is required'),
      max_salary: Yup.string().required('Max Salary is required'),
      state: Yup.string().required('State is required'),
      city: Yup.string().required('City is required'),
      category_id: Yup.number().required('Category is required').positive('Category is required'),
      description: Yup
        .string().required('Description is required')
        .matches(
          /[a-zA-Z]/,
          'Insert at least one character'
        ),
    },);
    return (
      //Required for all blocks
      <MainComponent
        state={this.state}
        postValidationSchema={postValidationSchema}
        postCreatePost={this.postCreatePost}
        finalErrorMessage={this.finalErrorMessage}
      />
    );

    // Customizable Area End
  }
}
// Customizable Area Start
/* istanbul ignore next */
function MainComponent(props: any) {
  return (
    <>
      <HeaderWeb />

      <Grid container style={{ justifyContent: "center", background: "#F2F2F2" }}>
        <Grid item xs={11}>
          <Card style={webStyle.card}>
            <Box sx={{ width: "100%" }}>
              {props.state.pageLoading ? <div style={webStyle.root}>
                <div style={webStyle.circularContainer}>
                  <CircularProgress />
                </div>
              </div> :
                <Formik
                  initialValues={props.state.initialDatas}
                  validationSchema={() => props.postValidationSchema}
                  onSubmit={(values: any, actions: any) => {
                    props.postCreatePost(values).then(() => {
                      actions.resetForm({
                        values: {
                          company_name: "",
                          position: "",
                          min_salary: '',
                          max_salary: '',
                          clearance: "",
                          priority_level: "",
                          state: "",
                          city: "",
                          category_id: 1,
                          account_id: "",
                          description: "",
                        }
                      })
                    })
                  }}
                  data-test-id="formPostCreationDetails"
                >
                  {({ errors, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }: any) => (
                    <form data-test-id="formPostCreationDetails" onSubmit={handleSubmit}>
                      <Grid container style={webStyle.postCreationText}>
                        ADD NEW JOB OPENING </Grid>
                      <Grid container style={webStyle.input}>
                        <InputLabel style={{
                          marginTop: "14px",
                          fontWeight: 500,
                          color: 'rgba(0, 0, 0, 1)',
                          fontSize: "18px",
                          fontFamily: 'Roboto',
                        }}>
                          Company & Job Details :
                        </InputLabel>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={6} style={webStyle.input}>
                          <Input
                            name='company_name'
                            onChange={handleChange}
                            placeholder="Add company name "
                            error={errors.company_name && touched.company_name}
                          />
                          {props.finalErrorMessage(errors.company_name, touched.company_name)}
                        </Grid>
                        <Grid item xs={12} md={6} style={webStyle.input}>
                          <Input
                            placeholder="Position"
                            name='position'
                            onChange={handleChange}
                            error={errors.position && touched.position}
                          />
                          {props.finalErrorMessage(errors.position, touched.position)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} md={4} style={webStyle.input}>
                          <InputField
                            id="filled-select-currency-native"
                            select
                            name="clearance"
                            InputProps={{ disableUnderline: true }}
                            placeholder="Choose clearance"
                            error={errors.clearance && touched.clearance}

                            onChange={handleChange}
                            variant="filled"
                          >
                            {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </InputField>
                          {props.finalErrorMessage(errors.clearance, touched.clearance)}
                        </Grid>
                        <Grid item xs={12} md={4} style={webStyle.input}>
                          <InputField
                            id="filled-select-currency-native"
                            select
                            name="priority_level"
                            InputProps={{ disableUnderline: true }}
                            placeholder="Select priority level"
                            disableUnderline={false}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="filled"
                            error={errors.priority_level && touched.priority_level}

                          >
                            {priority_level.map((option: any) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </InputField>
                          {props.finalErrorMessage(errors.priority_level, touched.priority_level)}

                        </Grid>
                        <Grid item xs={12} md={4} style={webStyle.input}>
                          <Select
                            id="demo-controlled-open-select"
                            value={values.category_id}
                            name='category_id'
                            onChange={(event: any) => setFieldValue('category_id', event.target.value)}
                            input={<SelectInput id="select-multiple-placeholder" />}
                          >
                            <MenuItem value={-1} disabled>
                              Category
                            </MenuItem>
                            {props.state.categoryDetails.map((item: any) => (
                              <MenuItem
                                key={item.attributes.id}
                                value={item.attributes.id}
                              >
                                {item.attributes.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {props.finalErrorMessage(errors.category_id, touched.category_id)}
                        </Grid>
                      </Grid>
                      <Grid container style={{ justifyContent: 'space-between', display: 'flex' }}>
                        <Grid item xs={12} md={5} lg={4} style={{ paddingLeft: '9px', display: 'flex', justifyContent: 'space-between' }}>
                          <Box style={{ marginRight: '15px' }}>
                            <Typography
                              style={webStyle.labeltext}>Min Salary :</Typography>
                            <Input
                              name="min_salary"
                              placeholder="Enter min Salary"
                              onChange={handleChange}
                              value={values.min_salary}
                              error={errors.min_salary && touched.min_salary}
                            />
                            {props.finalErrorMessage(errors.min_salary, touched.min_salary)}
                          </Box>
                          <Box style={{ marginRight: '15px' }}><Typography
                            style={webStyle.labeltext}>Max Salary :</Typography>
                            <Input
                              name="max_salary"
                              placeholder="Enter max Salary"
                              onChange={handleChange}
                              value={values.max_salary}
                              error={errors.max_salary && touched.max_salary}
                            />
                            {props.finalErrorMessage(errors.max_salary, touched.max_salary)}

                          </Box></Grid>
                        <Grid item xs={12} md={7} lg={8}>
                          <Grid container style={webStyle.labeltext}><span style={{ paddingLeft: '9px' }}>Address :</span></Grid>
                          <Grid container style={{ marginLeft: '-9px' }}>
                            <Grid item xs={12} md={6} style={webStyle.input}>
                              <InputField
                                id="filled-select-currency-native"
                                select
                                name="state"
                                InputProps={{ disableUnderline: true }}
                                placeholder="Select State"
                                disableUnderline={false}
                                onChange={handleChange}
                                error={errors.state && touched.state}
                                variant="filled"
                              >
                                {state.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </InputField>
                              {props.finalErrorMessage(errors.state, touched.state)}
                            </Grid>
                            <Grid item xs={12} md={6} style={webStyle.input}>
                              <InputField
                                id="filled-select-currency-native"
                                select
                                name="city"
                                placeholder="Select City"
                                InputProps={{ disableUnderline: true }}
                                onChange={handleChange}
                                error={errors.city && touched.city}
                                variant="filled"
                                values={values.city}
                                type={props.state.city}
                                onBlur={handleBlur}
                              >
                                {city.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </InputField>
                              {props.finalErrorMessage(errors.city, touched.city)}
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                      <Grid container style={webStyle.labeltext}><p style={{ paddingLeft: '9px', marginBottom: "13px", marginTop: "0px" }}>Job Description :</p></Grid>
                      <Grid item xs={12} style={{ paddingLeft: '9px', marginBottom: '50px' }}>
                        <ReactQuill

                          style={{ height: "8%" }}
                          onChange={(html: any) => { html.replace(configJSON.descriptionValidation, '').trim().length === 0 && !html.includes("<img") ? setFieldValue('description', '') : setFieldValue('description', html) }}
                          theme="snow"
                          value={values.description}
                          placeholder="Type something..."
                        />
                      </Grid>
                      <Grid item xs={12} style={{ paddingLeft: '9px' }}>
                        {props.finalErrorMessage(errors.description, touched.description)}</Grid>
                      <Grid container style={{ justifyContent: "end" }}>
                        <Button
                          style={{
                            marginTop: "13px",
                            background: "#FF090A",
                            borderRadius: "4px",
                            color: "white",
                            textTransform: "capitalize",
                            fontSize: "18px",
                            fontWeight: 500,
                            height: "65px",
                            width: "30%"
                          }}
                          type="submit">Add New Job Opening</Button>
                      </Grid>
                    </form>
                  )}
                </Formik>}
            </Box>
          </Card>
        </Grid>

        <Snackbar autoHideDuration={2000} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
          open={props.state.errorOpenNotification} >
          <MuiAlert elevation={6} variant="filled" severity={props.state.isError ? 'error' : 'success'} >
            {props.state.messageText}
          </MuiAlert>
        </Snackbar>
      </Grid>
    </>
  )
}
// Customizable Area End

// Customizable Area Start

const webStyle: any = {
  card: {
    background: "#FFFF",
    borderRadius: "8px",
    marginTop: "44px",
    padding: "0px 88px 50px 100px"
  },
  postCreationText: {
    fontSize: "24px",
    fontWeight: "500",
    fontStyle: 'normal',
    padding: "35px 12px 12px",
    color: 'rgba(14, 14, 14, 1)',
    fontFamily: 'Roboto',
  },
  fieldError: {
    color: "red",
    fontSize: "14px",
    margin: "5px 5px 5px 0px"
  },
  cursorPointer: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center'
  },
  padding9: {
    paddingLeft: "9px"
  },
  labeltext: {
    marginTop: "25px",
    fontWeight: "500",
    color: 'rgba(0, 0, 0, 1)',
    fontSize: "18px",
    fontFamily: 'Roboto',
  },
  width100: {
    width: "100%"
  },
  input: {
    padding: "0px 9px"
  },

  textArea: {
    width: "100%",
    background: "#F2F2F2",
    border: "none",
  },

}
const SelectInput: any = withStyles({
  root: {
    width: '100%',
    "& .MuiInputBase-input": {
      background: "#F2F2F2",
      width: "100%",
      borderRadius: "4px",
      marginTop: "13px",
      fontFamily: 'Roboto',
      fontWeight: "Regular",
      height: "20px",
      padding: "20px",
      color: "rgba(132, 132, 132, 1)",
    },
    "& .MuiSvgIcon-root": {
      color: "black",
      paddingTop: "12px",
    }
  }
})(InputBase);
const Input: any = withStyles({
  root: {
    width: '100%',
    "& .MuiInputBase-input": {
      background: "#F2F2F2",
      width: "100%",
      borderRadius: "4px",
      paddingLeft: "20px",
      marginTop: "13px",
      fontFamily: 'Roboto',
      fontWeight: "Regular",
      height: "45px",
    }
  }
})(InputBase);

const InputField: any = withStyles({
  root: {
    width: '100%',
    "& .MuiFilledInput-root": {
      fontWeight: "Regular",
      marginTop: "13px",
      fontFamily: 'Roboto',
      background: "#F2F2F2",
      color: "rgba(132, 132, 132, 1)",
      borderRadius: "4px",
      border: "none",
      "& .MuiFilledInput-input": {
        paddingTop: "20px",
        paddingBottom: "20px",
        textAlign: "center",
      },
      "& .MuiSelect-nativeInput": {
        opacity: 1,
        background: "#F2F2F2",
        border: "none",
        fontSize: "16px",
        color: 'rgba(132, 132, 132, 1)',
        padding: "20px",
        fontFamily: 'Roboto',
        fontWeight: "Regular",
      },
      "& .MuiSelect-iconFilled": {
        color: "black",
      },
      "& .MuiSelect-select.MuiSelect-select ": {
        background: "#F2F2F2",
      },
      "& fieldset": {
        borderRadius: "4px",
        fontWeight: 400,
      },
    },
    "& ::placeholder": {
      opacity: 1,
    }
  }

})(TextField);

const InputSelectField: any = withStyles({
  root: {
    width: '100%',
    "& .MuiFilledInput-root": {
      fontWeight: "500",
      marginTop: "13px",
      fontFamily: 'Roboto',
      background: "#F2F2F2",
      color: "rgba(0, 0, 0, 1)",
      borderRadius: "4px",
      display: 'flex',
      justifyContent: 'center',
      border: "none",
      "& .MuiFilledInput-input": {
        paddingTop: "20px",
        paddingBottom: "20px",
        textAlign: "center"
      },
      '& .MuiFilledInput-inputAdornedEnd': {
        width: '20%',
        textAlign: 'left',
        marginLeft: '5px'
      },
      '&.MuiFilledInput-root.MuiFilledInput-inputAdornedEnd': {
        fontWeight: 500
      },
      "& .MuiSelect-nativeInput": {
        opacity: 1,
        background: "#F2F2F2",
        border: "none",
        fontSize: "18px",
        color: 'rgba(0, 0, 0, 1)',
        padding: "20px",
        fontFamily: 'Roboto',
        fontWeight: "normal",
      },

      "& .MuiSelect-select.MuiSelect-select ": {
        background: "#F2F2F2",
      },
      "& fieldset": {
        borderRadius: "4px",
        fontWeight: 400,
      },
    },
    "& ::placeholder": {
      opacity: 1,
    }
  }

})(TextField);
// Customizable Area End
