import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { apiCall } from "../../../components/src/Common";
import React from "react";
// Customizable Area End
export const configJSON = require("./config.js");
export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  jobData: {
    type: string;
    quantity: string;
  }[]; token: string; errorMsg: string; loading: boolean; popoverStatus: boolean, popoverRef: any,

  jobSearch: string,
  currntJobData: any,
  pageData: {
    selctedcity: string,
    cleaarenace: string;
    pairoty: string;
    title: string;
    department: string;
    category: any;
    statenew: string;
    postion: string,
    compenyname: string,
  }
  categoryDetails: any,
  errorOpenNotification: boolean,
  isError: boolean,
  messageText: string
  // Customizable Area End
}
interface SS { }
export default class CurrentJobOpeningBlockController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  matchidForjoidsercah: string = ""
  postDataId: string = ""
  getdataID: string = ""
  getCategoryData: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];
    this.state = {
      jobData: [],
      errorMsg: "",
      token: "",
      loading: false,
      popoverStatus: false,
      popoverRef: null,
      jobSearch: '',
      currntJobData: [],
      categoryDetails: [],
      pageData: {
        selctedcity: '',
        cleaarenace: "",
        pairoty: "",
        title: "",
        department: "",
        category: "",
        statenew: "",
        postion: '',
        compenyname: '',
      },
      errorOpenNotification: false,
      isError: false,
      messageText: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getPostedData()
    super.componentDidMount();
    // Customizable Area Start
    this.getCategory();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    /* istanbul ignore next */
    if (apiRequestCallId != null) {
      /* istanbul ignore next */
      if (apiRequestCallId === this.matchidForjoidsercah) {
        { this.setState({ currntJobData: responseJson }) }
      }
      /* istanbul ignore next */
      if (apiRequestCallId === this.getdataID) {
        /* istanbul ignore next */
        { this.setState({ currntJobData: responseJson }) }
      }

      if (apiRequestCallId === this.getCategoryData) {
        /* istanbul ignore next */
        this.setState({ categoryDetails: responseJson.data })

      }

      if (apiRequestCallId === this.postDataId) {
        if (!responseJson.error && responseJson) {
          /* istanbul ignore next */
          this.setState({ isError: false, errorOpenNotification: true, messageText: 'Current job added successfully' },()=>{
            this.getPostedData();
          })
          setTimeout(() => {
            this.setState({ errorOpenNotification: false })
          }, 2000);
        } else {
          /* istanbul ignore next */
          this.setState({ isError: true, errorOpenNotification: true, messageText: responseJson?.meta?.message || 'Current job not added.' })
          setTimeout(() => {
            this.setState({ errorOpenNotification: false })
          }, 2000);
        }
      }

    }

    // Customizable Area End

  }
  openPopover = (e: any) => {
    this.setState({ popoverRef: e.currentTarget, popoverStatus: true })
  }

  closePopover = () => {
    this.setState({ popoverRef: null, popoverStatus: false })
  }
  /* istanbul ignore next */
  handleJobsearch = (event: any) => {
    this.setState({ jobSearch: event.target.value })
    setTimeout(() => {
      this.handleCurrntJobsearch()
    }, 2500);
  }
  /* istanbul ignore next */
  handleCurrntJobsearch = async () => {
    const header = {
      "token": localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.matchidForjoidsercah = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts?search=${this.state.jobSearch}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  handlePostdata = async (values: any) => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.contentTypeApiPostCreationPost, token: token
    };

    const attrs = {
      "company_name": values.company_name,
      "position": values.position,
      "clearance": values.clearance_level,
      "priority_level": values.priority_level,
      "category_id": String(values.category),
      "max_sal": `${values.max_salary}`,
      "min_sal": `${values.min_salary}`,
      "state": values.select_state,
      "city": values.select_city,
      "description": values.description,
    };

    const currentJobData = {
      attributes: attrs,
    };

    const httpBody = {
      data: currentJobData,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


  };
  //getposteData  Api
  getPostedData = async () => {
    const header = {
      "token": localStorage.getItem("token")

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getdataID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  finalErrorMessage(error: string, touch: string) {
    /* istanbul ignore next */
    if (error && touch) {
      /* istanbul ignore next */
      return <div style={{
        color: "red",
        fontSize: "14px",
        margin: "10px 10px 10px 0px"
      }}>
        {error}
      </div>
    }
  }
  getCategory() {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.contentTypeApiPostCreationPost,
    };

    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: `${configJSON.endPointCategory}?token=${token}`,
      httpMethod: configJSON.methodApiCategory,
    });
    this.getCategoryData = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
}
  // Customizable Area Start
  // Customizable Area End

