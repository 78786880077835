import React from "react";
import {
    Box,
    Button,
    Typography,
    LinearProgress,
    MenuItem,
    TextField,
    Grid,
    Select,
    InputLabel,
    Input,
    Tab,
    Divider
} from "@material-ui/core";
import "./Dashboard.css";
import { withStyles } from "@material-ui/core/styles";
import HeaderWeb from "../../../components/src/Header.web";
import { searchWhite } from './assets';
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import TabContext from "@material-ui/lab/TabContext";
import { Chart } from "react-google-charts";
import DashboardController, { Props } from "./DashboardController.web";
import { DataGrid } from "@material-ui/data-grid";
import Graph from "./Graph.web";
import TransitEnterexitIcon from '@material-ui/icons/TransitEnterexit';
import SortIcon from '@material-ui/icons/Sort';
import { infoIcon1 } from './assest1';

const data = [
    ["Task", "Hours per Day"],
    ["Urgent - 50%", 12],
    ["Cautious - 25%", 6],
    ["Strong Submission - 25%", 6],
    //24
];

const options = {
    title: "Priority Jobs",
    colors: ['#ff969c', '#cfa7de', '#659af7',],
    pieSliceText: 'none',
    titleTextStyle: {
        fontSize: 18,
        bold: true,
        fontName: 'roboto',
    }

};
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);

    }

    render() {

        return (

            <MainComponent
                state={this.state}
                handleStateChange={this.handleStateChange}
                handleChangeTab={this.handleChangeTab}
                handleSearchCandidate={this.handleSearchCandidate}
            />

        );
    }
}

/* istanbul ignore next */
function MainComponent(props: any) {
    let arr = props.state.activeCandidateList.length > 0 ? props.state.activeCandidateList.map((item: any) => ({
        "id": item.id,
        "candidate_name": item.attributes.candidate_name,
        "job_type": item.attributes.job_type ? item.attributes.job_type : '-',
        "company_name": item.attributes.company_name ? item.attributes.company_name : '-',
        "city": `${item.attributes.city ? item.attributes.city : ''} , ${item.attributes.state ? item.attributes.state : ''}`,
        "submission_date": item.attributes.submission_date ? item.attributes.submission_date : '-',
        "interview_date": item.attributes.interview_date ? item.attributes.interview_date : '-',
        'status': item.attributes.status
    })
    ) : []
    return (
        <>
            <HeaderWeb />
            <Box style={{ padding: "30px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <Box style={webStyle.boxShadow}>
                            <Grid container spacing={2}>
                                <div style={webStyle.candidateHeader}>
                                    <Typography variant="h4">Candidate Search</Typography>
                                    <span style={webStyle.candidateImage}>Boolean search <img src={infoIcon1} width={18} style={{ marginLeft: 10 }} /></span>
                                </div>
                                <Grid item xs={12}>
                                    <Box style={{ marginBottom: 20 }}>
                                        <Input
                                            id="jobtype"
                                            name="jobtype"
                                            placeholder="Keywords result for :“Executive” or “Technical” or “Administrative”"
                                            onChange={(e) => props.handleStateChange(e)}
                                            className="search-field"
                                            margin='dense'
                                        />
                                    </Box>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <label htmlFor="Clearance-level" style={{ fontWeight: 'bold', fontFamily: 'roboto' }}>Clearance Level :</label>
                                        <InputField
                                            select
                                            name="clearance_level"
                                            InputProps={{ disableunderline: true }}
                                            placeholder="Select Clearance Level"
                                            onChange={(e: any) => props.handleStateChange(e)}
                                            variant="filled"
                                        >
                                            {props.state.clearance.map((option: any) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </InputField>


                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>

                                        <label htmlFor="jobPlateform" style={{ fontWeight: 'bold', fontFamily: 'roboto' }}>Job Platforms :</label>

                                        <InputField

                                            select
                                            name="job_platforms"
                                            InputProps={{ disableunderline: true }}
                                            placeholder="Select Job Platforms"
                                            onChange={(e: any) => props.handleStateChange(e)}
                                            variant="filled"
                                        >
                                            {props.state.platformArr.map((option: any) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </InputField>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <InputLabel style={{
                                            fontSize: "15px",
                                            border: "none",
                                            color: "black",
                                            fontWeight: 'bold',
                                            fontFamily: 'roboto'
                                        }}>Experience :</InputLabel>
                                        <InputField
                                            id="experience"
                                            select
                                            name="experience"
                                            InputProps={{ disableunderline: true }}
                                            placeholder="Select Experience Level"
                                            onChange={(e: any) => props.handleStateChange(e)}
                                            variant="filled"
                                        >
                                            {props.state.experienceArr.map((option: any) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </InputField>
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <InputLabel style={{
                                            fontSize: "17px",
                                            border: "none",
                                            color: "black",
                                            fontWeight: 'bold',
                                            fontFamily: 'roboto'
                                        }}>Location :</InputLabel>
                                        <InputField

                                            select
                                            name="select_state"
                                            InputProps={{ disableunderline: true }}
                                            placeholder="Select State"
                                            onChange={(e: any) => props.handleStateChange(e)}

                                            variant="filled"
                                        >
                                            {props.state.stateArr.map((option: any) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </InputField>
                                    </Grid>


                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <InputField

                                            select
                                            style={{ marginTop: 15 }}
                                            name="select_city"
                                            placeholder="City"
                                            InputProps={{ disableunderline: true }}
                                            onChange={(e: any) => props.handleStateChange(e)}
                                            variant="filled"
                                        >
                                            {props.state.cityArr.map((option: any) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.value}
                                                </MenuItem>
                                            ))}
                                        </InputField>
                                    </Grid>
                                </Grid>


                                <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label className="label" htmlFor="profilePeriod" style={{ fontWeight: 'bold', fontFamily: 'roboto' }}>Profile Active :</label>

                                        <Select
                                            defaultValue={"1-90 days"}
                                            name="profile"
                                            onChange={(e: any) => props.handleStateChange(e)}
                                        >
                                            <MenuItem value="1-90 days">1-90 days</MenuItem>
                                            <MenuItem value="90-180 days">90-180 days</MenuItem>
                                            <MenuItem value="181-365 days">181-365 days</MenuItem>

                                        </Select>
                                    </div>

                                    <Button style={{
                                        backgroundColor: '#6063ba',
                                        textTransform: "capitalize",
                                        height: '50px',
                                        textAlign: 'center',
                                        width: '200px',
                                        color: 'white',
                                        marginTop: 20,
                                        marginBottom: 20,

                                    }} hidden={!props.state.booleanSearch}
                                        onClick={() => { props.handleSearchCandidate() }}
                                    >
                                        <img src={searchWhite} width={15} />Search
                                    </Button>
                                </Box>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box style={webStyle.boxShadow}>
                            <Box style={webStyle.viewDetail}>
                                <span style={{ color: 'rgb(151, 88, 240', fontWeight: 'bold', fontFamily: 'roboto' }}>View Detail</span>
                                <TransitEnterexitIcon style={{ transform: 'rotate(180deg)', marginLeft: '10px', color: 'rgb(151, 88, 240)' }} />
                            </Box>
                            <Chart
                                chartType="PieChart"
                                data={data}
                                options={options}
                                width={"100%"}
                                height={"371px"}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box style={webStyle.boxShadow}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <Box>
                                        <Typography style={{ fontSize: "1.5rem", textAlign: 'center', fontWeight: 'bold', marginTop: 20 }}>Total Candidates</Typography>
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                marginTop: 30,

                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: "grey",
                                                    fontSize: "3rem",
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {props.state.totalCandidates}
                                            </Typography>
                                            <br />
                                            <Typography
                                                style={{ fontWeight: 'bold', textTransform: "capitalize" }}
                                            >
                                                Candidates
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <Box>

                                            <Box style={{ margin: "1rem 0rem" }}>

                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Typography>Interview with client</Typography>

                                                    <Typography style={{ fontSize: "1 rem" }}>
                                                        {props.state.interviWithClient}
                                                    </Typography>

                                                </Box>
                                                <LinearProgress
                                                    className={`progress-4`}
                                                    variant="determinate"
                                                    value={
                                                        (Number(props.state.interviWithClient) /
                                                            Number(props.state.totalCandidates)) *
                                                        100
                                                    }
                                                />

                                            </Box>
                                            <Box style={{ margin: "1rem 0rem" }}>

                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Typography>Submitted for feedback</Typography>

                                                    <Typography style={{ fontSize: "1 rem" }}>
                                                        {props.state.candidatesExpectingOffer}
                                                    </Typography>
                                                </Box>

                                                <LinearProgress
                                                    className={`progress-5`}
                                                    variant="determinate"
                                                    value={
                                                        (Number(props.state.candidatesExpectingOffer) /
                                                            Number(props.state.totalCandidates)) *
                                                        100
                                                    }
                                                />
                                            </Box>
                                            <Box style={{ margin: "1rem 0rem" }}>

                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Typography>Candidates expecting offer</Typography>
                                                    <Typography style={{ fontSize: "1 rem" }}>
                                                        {props.state.submittedForFeedback}
                                                    </Typography>
                                                </Box>
                                                <LinearProgress
                                                    className={`progress-6`}
                                                    variant="determinate"
                                                    value={
                                                        (Number(props.state.submittedForFeedback) /
                                                            Number(props.state.totalCandidates)) *
                                                        100
                                                    }
                                                />
                                            </Box>

                                            <Box style={{ margin: "1rem 0rem" }}>
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <Typography>Candidate accepted</Typography>
                                                    <Typography style={{ fontSize: "1 rem" }}>
                                                        {props.state.candidatesAccepted}
                                                    </Typography>
                                                </Box>
                                                <LinearProgress
                                                    className={`progress-7`}
                                                    variant="determinate"
                                                    value={
                                                        (Number(props.state.candidatesAccepted) /
                                                            Number(props.state.totalCandidates)) *
                                                        100
                                                    }
                                                />
                                            </Box>
                                        </Box>

                                        <Button
                                            style={{
                                                backgroundColor: '#6063ba',
                                                color: "white",
                                                borderRadius: "0",
                                                marginTop: "0.5rem",
                                                padding: "16px",
                                                textTransform: "capitalize"
                                            }}
                                        >
                                            View Detail
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Box style={webStyle.boxShadow}>
                            <Graph />
                        </Box>
                    </Grid>
                </Grid>
                <Box style={webStyle.boxShadow}>
                    <TabContext value={props.state.newValue}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "20px" }}>
                            <CandidateTab
                                onChange={props.handleChangeTab}
                                aria-label="lab API tabs example"
                            >
                                <Tab style={{ fontWeight: "bolder", textTransform: "capitalize", fontSize: '18px', marginRight: 40 }} label="Active Candidates" value="1" />
                                <Tab style={{ fontWeight: "bolder", textTransform: "capitalize", fontSize: '18px', marginRight: 40 }} label="Current Job Opening" value="2" />
                            </CandidateTab>
                            <Divider style={{ width: "30%" }} />

                        </Box>
                        <TabPanel value="1">
                            <Box style={webStyle.sortby}>
                                <SortIcon />
                                <span style={{ marginLeft: '10px', borderBottom: '1px solid black' }}>Sort by</span>
                            </Box>
                            <DataGrid
                                rows={arr}
                                columns={props.state.columns}
                                // pageSize={25}
                                checkboxSelection={false}
                                pagination
                                components={{
                                    Pagination: () => <></>,
                                }}
                                className="dashboard-datagrid"
                            />
                        </TabPanel>
                        <TabPanel value="2" style={{ marginTop: "30px" }}>
                            <Typography>pannel 2</Typography>
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box >
        </>
    )
}


const InputField: any = withStyles({
    root: {
        width: '100%',
        "& .MuiFilledInput-root": {
            fontWeight: "Regular",
            marginTop: "13px",
            fontFamily: 'Roboto',
            background: "#F2F2F2",
            color: "rgba(132, 132, 132, 1)",
            borderRadius: "4px",
            border: "none",
            "& .MuiFilledInput-input": {
                paddingTop: "20px",
                paddingBottom: "20px",
                textAlign: "center",
            },
            "& .MuiSelect-nativeInput": {
                opacity: 1,
                background: "#F2F2F2",
                border: "none",
                fontSize: "16px",
                color: 'rgba(132, 132, 132, 1)',
                padding: "20px",
                fontFamily: 'Roboto',
                fontWeight: "Regular",
            },
            "& .MuiSelect-iconFilled": {
                color: "black",
            },
            "& .MuiSelect-select.MuiSelect-select ": {
                background: "#F2F2F2",
            },
            "& fieldset": {
                borderRadius: "4px",
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
        }
    }

})(TextField);

const CandidateTab = withStyles({
    root: {
        "& .MuiTabs-scroller": {
            paddingLeft: "20px",
        },
        "& .PrivateTabIndicator-root-5": {
            backgroundColor: "#8f4af0",
            height: "7px",
            borderRadius: '20px'
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#8f4af0",
            height: "7px",
            borderRadius: '20px'
        }
    }

})(TabList);
const webStyle = {
    totalCandidateContainer: {
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        // padding: "0.5rem 1rem",
        // display: 'flex',

    },
    candidateHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 8,
        marginRight: 8
    },
    candidateImage: {
        fontFamily: 'roboto',
        display: 'flex',
        alignItems: 'center',
        color: '#6300ef',
        marginRight: 10
    },
    viewDetail: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    totalCandidateSubContainer: {
        display: "grid",
        gridTemplateRows: "3fr 7fr",
        alignItems: "start",
        justifyContent: "center",
        justifyItems: "center",
        color: "black",
        margin: "1rem 0rem",
        background: 'yellow'
    },
    adressmain: {
        justifyContent: "center",
        margin: "20px"
    },
    sortby: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '-60px',
        marginBottom: '20px'
    },
    adreslbale: {
        fontSize: "15px",
        border: "none",
        fontWeight: "bold",
        color: "black"
    },
    adreestextArea: {
        display: "flex",
        marginTop: "10px"
    },
    boxShadow: {
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 5px 30px',
        borderRadius: '5px',
        padding: 20,
        marginBottom: 20
    }
}
// Customizable Area End
