// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import './App.css';
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ImportContactsLinkedin2 from "../../blocks/ImportContactsLinkedin2/src/ImportContactsLinkedin2";
// import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import CfClearanceJobApi from "../../blocks/CfClearanceJobApi/src/CfClearanceJobApi";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearchScreen.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CfClearedjobsNetApi from "../../blocks/CfClearedjobsNetApi/src/CfClearedjobsNetApi";
import AccountScoreranking from "../../blocks/AccountScoreranking/src/AccountScoreranking";
import CfMicrosoftTeamIntegration from "../../blocks/CfMicrosoftTeamIntegration/src/CfMicrosoftTeamIntegration";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import CfCareerbuilderApi from "../../blocks/CfCareerbuilderApi/src/CfCareerbuilderApi";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import MailingListIntegration from "../../blocks/MailingListIntegration/src/MailingListIntegration.web";
import CfIntegrateWithJobDatabase from "../../blocks/CfIntegrateWithJobDatabase/src/CfIntegrateWithJobDatabase";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import TermsAndConditions3 from "../../blocks/TermsAndConditions3/src/TermsAndConditions3";
import CfLinkedinJobsApiIntegration from "../../blocks/CfLinkedinJobsApiIntegration/src/CfLinkedinJobsApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import OutlookIntegration from "../../blocks/OutlookIntegration/src/OutlookIntegration";
import DataEncryption from "../../blocks/DataEncryption/src/DataEncryption";
import CfIndeedApi2 from "../../blocks/CfIndeedApi2/src/CfIndeedApi2";
import CfDataEncryptionOfClearanceAndKeyData from "../../blocks/CfDataEncryptionOfClearanceAndKeyData/src/CfDataEncryptionOfClearanceAndKeyData";
import CvresumeCandidateManagement2 from "../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateManagement2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsoleToggle/AdminConsoleToggle.web";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import CfVideoConferenceViaTeam from "../../blocks/CfVideoConferenceViaTeam/src/CfVideoConferenceViaTeam";
import Dashboard from "../../blocks/dashboard/src/Dashboard"
import WithoutFeeSchedule from '../../blocks/dashboard/src/WithoutFeeSchedule.web';
import UserProfileBasicBlockWeb from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import AddCandidateBlockWeb from "../../blocks/CvresumeCandidateManagement2/src/AddCandidateBlock.web";
import PostCreationBasicBlockWeb from "../../blocks/user-profile-basic/src/PostCreationBasicBlockWeb.web";
import CurrentJobOpeningBlock from "../../blocks/user-profile-basic/src/CurrentJobOpeningBlock.web"
import EmailAccountActivation from "../../blocks/email-account-registration/src/EmailAccountActivation.web"
import FeeSchedules from "../../blocks/CvresumeCandidateManagement2/src/FeeSchedules.web"
import CandidateSearch from "../../blocks/dashboard/src/candidateSearch/CandidateSearch.web";
import CvresumeCandidateManagement2Web from "../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateManagement2.web";
import Prioritise from "../../components/src/Prioritise/Prioritise.web";
import AdHocReporting from "../../blocks/dashboard/src/AdHocReporting/AdHocReporting.web";
import OutlookIntegrationNew from "../../blocks/OutlookIntegration/src/OutlookIntegrationNew.web";

const routeMap = {
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  ImportContactsLinkedin2: {
    component: ImportContactsLinkedin2,
    path: "/ImportContactsLinkedin2"
  },
  CandidateSearch: {
    component: CandidateSearch,
    path: "/CandidateSearch",
  },
  dataEncryption: {
    component: CandidateSearch,
    path: "/dataEncryption",
  },
  accountScoreRanking: {
    component: CandidateSearch,
    path: "/accountScoreRankingMain",
  },
  Prioritise: {
    component: Prioritise,
    path: "/Prioritise",
    exact: true,
  },
  
  // MobileAccountLoginBlock:{
  //  component:MobileAccountLoginBlock,
  // path:"/MobileAccountLoginBlock"},
  CfClearanceJobApi: {
    component: CfClearanceJobApi,
    path: "/CfClearanceJobApi"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  CfClearedjobsNetApi: {
    component: CfClearedjobsNetApi,
    path: "/CfClearedjobsNetApi"
  },
  AccountScoreranking: {
    component: AccountScoreranking,
    path: "/AccountScoreranking"
  },
  CfMicrosoftTeamIntegration: {
    component: CfMicrosoftTeamIntegration,
    path: "/CfMicrosoftTeamIntegration"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  CfCareerbuilderApi: {
    component: CfCareerbuilderApi,
    path: "/CfCareerbuilderApi"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  MailingListIntegration: {
    component: MailingListIntegration,
    path: "/MailingListIntegration"
  },
  CfIntegrateWithJobDatabase: {
    component: CfIntegrateWithJobDatabase,
    path: "/CfIntegrateWithJobDatabase"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: "/TermsAndConditions3"
  },
  CfLinkedinJobsApiIntegration: {
    component: CfLinkedinJobsApiIntegration,
    path: "/CfLinkedinJobsApiIntegration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  OutlookIntegration: {
    component: OutlookIntegration,
    path: "/OutlookIntegration"
  },
  DataEncryption: {
    component: DataEncryption,
    path: "/DataEncryption"
  },
  CfIndeedApi2: {
    component: CfIndeedApi2,
    path: "/CfIndeedApi2"
  },
  CfDataEncryptionOfClearanceAndKeyData: {
    component: CfDataEncryptionOfClearanceAndKeyData,
    path: "/CfDataEncryptionOfClearanceAndKeyData"
  },
  CvresumeCandidateManagement2: {
    component: CvresumeCandidateManagement2,
    path: "/CvresumeCandidateManagement2"
  },
  CvresumeCandidateManagement2Web: {
    component: CvresumeCandidateManagement2Web,
    path: "/CvresumeCandidateManagement2web"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailAccountActivation: {
    component: EmailAccountActivation,
    path: "/EmailAccountActivation"
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: "/ElasticSearch"
  },

  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    exact: true,
    path: "/"
  },


  CfVideoConferenceViaTeam: {
    component: CfVideoConferenceViaTeam,
    path: "/CfVideoConferenceViaTeam"
  },

  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },


  Home: {
    component: WithoutFeeSchedule,
    path: '/WithoutFeeSchedule'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  UserProfileBasicBlockWeb: {
    component: UserProfileBasicBlockWeb,
    path: "/UserProfileBasicBlockWeb"
  },
  PostCreationBasicBlockWeb: {
    component: PostCreationBasicBlockWeb,
    path: "/PostCreationBasicBlockWeb"
  },
  AddCandidateBlockWeb: {
    component: AddCandidateBlockWeb,
    path: "/AddCandidateBlockWeb"
  },

  CurrentJobOpeningBlock: {
    component: CurrentJobOpeningBlock,
    path: "/CurrentJobOpeningBlock"
  },
  FeeSchedules: {
    component: FeeSchedules,
    path: "/FeeSchedules"
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: "/AdHoc"
  },
  OutlookIntegrationNew: {
    component: OutlookIntegrationNew,
    path: "/OutlookIntegrationNew"
  },
};



const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>

        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />

      </View>
    );
  }
}

export default App;
