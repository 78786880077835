import React from "react";
import {
    // Customizable Area Start
    Grid,
    InputLabel,
    TextField,
    InputBase,
    Box,
    Button,
    MenuItem,
    Snackbar
    //Customizable Area End
} from "@material-ui/core";
//Customizable Area Start
import 'date-fns';
import * as Yup from 'yup'
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import MuiAlert from '@material-ui/lab/Alert';
import { Formik } from "formik";
import { Footer } from "../../../components/src/Footer.web";
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const theme = createTheme({
    palette: {
        primary: {
            main: "#ebe534",
            contrastText: "#fff",
        },
    },
});
const stateOptions = [
    {
        value: 'Gujarat',
    },
    {
        value: 'Rajsthan',
    },
    {
        value: 'Bihar',
    },
    {
        value: 'Maharastra',
    },
];
const city = [
    {
        value: 'Morbi',
    },
    {
        value: 'Rajkot',
    },
    {
        value: 'Surat',
    },
    {
        value: 'Ahemdabad',
    },
];


// Customizable Area End
import AddCandidateBlockController, {
    Props,
} from "./AddCandidateBlockController.web";
import HeaderWeb from "../../../components/src/Header.web";
import { FormatDate } from "../../../components/src/FormatDate.web";
export default class AddCandidateBlockWeb extends AddCandidateBlockController {
    render() {
        return (
            // Customizable Area Start
            <>
                <MainComponent addNewCandidate={this.addNewCandidate} finalErrorMessage={this.finalErrorMessage} state={this.state} />
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
/* istanbul ignore next */
function MainComponent(props: any) {
    return (
        <>
            <HeaderWeb />
            <ThemeProvider theme={theme}>
                <Box p={"58px"}>
                    <Grid container style={webStyle.postCreationText}>
                        Add Candidate
                    </Grid>
                    <Formik
                        validateOnChange
                        validateOnBlur
                        initialValues={{
                            candidate_name: '',
                            company_name: '',
                            position: "",
                            recruiter: '',
                            select_state: '',
                            select_city: '',
                            submission_date: '',
                            interview_date: '',
                            category: '',
                            clearance_level: ''
                        }}
                        validationSchema={Yup.object().shape({
                            candidate_name: Yup.string().required('Candidate name is required').matches(
                                /[a-zA-Z]/,
                                'Insert at least one character'
                            ),
                            company_name: Yup.string().required('Company name is required')
                                .matches(
                                    /[a-zA-Z]/,
                                    'Insert at least one character'
                                ),
                            position: Yup.string()
                                .required('Position is required')
                                .matches(
                                    /[a-zA-Z]/,
                                    'Insert at least one character'
                                ),
                            category: Yup.string()
                                .required('Category is required')
                                .matches(
                                    /[a-zA-Z]/,
                                    'Insert at least one character'
                                ),
                            clearance_level: Yup.string()
                                .required('Clearance level is required')
                                .matches(
                                    /[a-zA-Z]/,
                                    'Insert at least one character'
                                ),
                            recruiter: Yup.string().required('Recruiter name is required'),

                            select_state: Yup.string().required('State is required'),
                            select_city: Yup.string().required('City is required'),
                            submission_date: Yup.string().required('Submission date is required'),
                            interview_date: Yup.string().required('Interview date is required'),

                        })}
                        onSubmit={(values: any, actions: any) => {
                            props.addNewCandidate(values).then(() => {
                                actions.setSubmitting(false);
                                actions.resetForm({
                                    values: {
                                        candidate_name: '',
                                        company_name: '',
                                        position: "",
                                        recruiter: '',
                                        select_state: '',
                                        select_city: '',
                                        submission_date: '',
                                        interview_date: '',
                                        category: '',
                                        clearance_level: ''
                                    }
                                })
                            });
                        }}
                    >

                        {({ errors, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }: any) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} style={webStyle.input}>
                                        <Grid container>
                                            <InputLabel style={webStyle.labeltext}>
                                                Candidate Name :
                                            </InputLabel>
                                        </Grid>
                                        <Grid container>
                                            <InputField
                                                name='candidate_name'
                                                placeholder="Candidate Name"
                                                onChange={handleChange}
                                                variant="outlined"
                                                value={values.candidate_name}
                                                error={errors.candidate_name && touched.candidate_name}
                                            />
                                            {props.finalErrorMessage(errors.candidate_name, touched.candidate_name)}
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} md={6} style={webStyle.input}>
                                        <Grid container>
                                            <InputLabel style={webStyle.labeltext}>
                                                Company Name :
                                            </InputLabel>
                                        </Grid>
                                        <Grid container>
                                            <InputField
                                                onChange={handleChange}
                                                name='company_name'
                                                placeholder=" Enter Company Name "
                                                variant="outlined"
                                                value={values.company_name}
                                                error={errors.company_name && touched.company_name}
                                            />
                                            {props.finalErrorMessage(errors.company_name, touched.company_name)}

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} style={webStyle.input}>
                                        <Grid container>
                                            <InputLabel style={webStyle.labeltext}>
                                                Category :
                                            </InputLabel>
                                        </Grid>
                                        <Grid container>
                                            <InputField
                                                name='category'
                                                onChange={handleChange}
                                                placeholder="Select Category"
                                                variant="outlined"
                                                value={values.category}
                                                error={errors.category && touched.category}
                                            />
                                            {props.finalErrorMessage(errors.category, touched.category)}
                                        </Grid >
                                    </Grid >
                                    <Grid item xs={12} md={6} style={webStyle.input}>
                                        <Grid container>
                                            <InputLabel style={webStyle.labeltext}>
                                                Position :
                                            </InputLabel>
                                        </Grid>
                                        <Grid container>
                                            <InputField
                                                id="outlined-select-currency-native"
                                                select
                                                name="position"
                                                onChange={handleChange}
                                                error={errors.position && touched.position}
                                                placeholder="Select Position "
                                                variant="outlined"
                                                value={values.position}
                                            >
                                                {props.state.positionArr.map((option: any) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                            </InputField>
                                            {props.finalErrorMessage(errors.position, touched.position)}
                                        </Grid>
                                    </Grid>
                                </Grid >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} style={webStyle.input}>
                                        <Grid container>
                                            <InputLabel style={webStyle.labeltext}>
                                                Clearence Level :
                                            </InputLabel>
                                        </Grid>
                                        <Grid container>
                                            <InputField
                                                name='clearance_level'
                                                placeholder="Select Clearence Level "
                                                variant="outlined"
                                                value={values.clearance_level}
                                                error={errors.clearance_level && touched.clearance_level}
                                                onChange={handleChange}
                                            />
                                            {props.finalErrorMessage(errors.clearance_level, touched.clearance_level)}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6} style={webStyle.input}>
                                        <Grid container>
                                            <InputLabel style={webStyle.labeltext}>
                                                Recruiter Name :
                                            </InputLabel>
                                        </Grid>
                                        <Grid container>

                                            <InputField
                                                id="outlined-select-currency-native"
                                                select
                                                name="recruiter"
                                                error={errors.recruiter && touched.recruiter}
                                                placeholder="Enter Name"
                                                variant="outlined"
                                                value={values.recruiter}
                                                onChange={handleChange}
                                            >
                                                {['John', 'Rathot', "Robert", "Samuales"].map((option: any) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </InputField>
                                            {props.finalErrorMessage(errors.recruiter, touched.recruiter)}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} style={webStyle.input}>
                                        <Grid container>
                                            <InputLabel style={webStyle.labeltext}>
                                                Address :
                                            </InputLabel>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item sm={6} md={6}>
                                                <InputField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    name="select_state"
                                                    placeholder="Select state"
                                                    value={values.select_state}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    error={errors.select_state && touched.select_state}

                                                >
                                                    {stateOptions.map((option: any) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.value}
                                                        </MenuItem>
                                                    ))}
                                                </InputField>
                                                {props.finalErrorMessage(errors.select_state, touched.select_state)}
                                            </Grid>
                                            <Grid item sm={6} md={6}>
                                                <InputField
                                                    id="outlined-select-currency-native"
                                                    select
                                                    name="select_city"
                                                    error={errors.select_city && touched.select_city}
                                                    placeholder="Select city"
                                                    onChange={handleChange}
                                                    value={values.select_city}
                                                    variant="outlined"

                                                >{city.map((option: any) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.value}
                                                    </MenuItem>
                                                ))}
                                                </InputField>
                                                {props.finalErrorMessage(errors.select_city, touched.select_city)}
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} md={6} style={webStyle.input}>
                                        <Grid container spacing={2}>
                                            <Grid item sm={6} md={6}>
                                                <InputLabel style={webStyle.labeltext}>
                                                    Submission date :
                                                </InputLabel>
                                                <InputField
                                                    id="outlined-select-currency-native"
                                                    name="submission_date"
                                                    type="date"
                                                    format="dd-mm-yyyy"
                                                    error={errors.submission_date && touched.submission_date}
                                                    onChange={handleChange}
                                                    value={values.submission_date}

                                                    variant="outlined" >
                                                </InputField>
                                                {props.finalErrorMessage(errors.submission_date, touched.submission_date)}
                                            </Grid>
                                            <Grid item sm={6} md={6}>
                                                <InputLabel style={webStyle.labeltext}>
                                                    Interview date :
                                                </InputLabel>
                                                <InputField
                                                    id="outlined-select-currency-native"
                                                    name="interview_date"
                                                    type="date"
                                                    format="dd-mm-yyyy"
                                                    error={errors.interview_date && touched.interview_date}
                                                    onChange={handleChange}
                                                    value={values.interview_date}
                                                    variant="outlined"
                                                    inputProps={{
                                                        min: new Date().toISOString().split("T")[0]
                                                    }}
                                                >
                                                </InputField>
                                                {props.finalErrorMessage(errors.interview_date, touched.interview_date)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container style={{ justifyContent: "end" }}>
                                    <Button
                                        style={{
                                            marginTop: "45px",
                                            marginBottom: "45px",
                                            background: "black",
                                            color: "white",
                                            textTransform: "capitalize",
                                            fontSize: "18px",
                                            fontWeight: 500,
                                            height: "55px",
                                            width: "18%"
                                        }}
                                        type="submit"
                                    >
                                        Add
                                    </Button>

                                </Grid>

                            </form>
                        )}
                    </Formik>
                </Box>
                <Snackbar autoHideDuration={2000} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                    open={props.state.errorOpenNotification} >
                    <Alert severity={props.state.isError ? 'error' : 'success'}>
                        {props.state.messageText}
                    </Alert>
                </Snackbar>
            </ThemeProvider >
            <Footer />
        </>
    )
}
const webStyle: any = {
    postCreationText: {
        fontSize: "30px",
        fontWeight: 500,
        fontStyle: 'normal',
        color: 'rgba(14, 14, 14, 1)',
        fontFamily: 'Roboto',
    },
    fieldError: {
        color: "red",
        fontSize: "14px",
        margin: "5px 5px 5px 0px"
    },
    labeltext: {
        marginTop: "33px",
        fontWeight: 500,
        color: 'rgba(0, 0, 0, 1)',
        fontSize: "15px",
        fontFamily: 'Roboto',
    },
}
const Input: any = withStyles({
    root: {
        width: '100%',
        "& .MuiInputBase-input": {
            background: "#F6F6F6",
            width: "100%",
            borderRadius: "4px",
            paddingLeft: "15px",
            marginTop: "8px",
            fontFamily: 'Roboto',
            fontWeight: "Regular",
            height: "48px",
            border: "1px solid #979797",
        }
    }
})(InputBase);

const InputField: any = withStyles({
    root: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            fontWeight: "Regular",
            marginTop: "8px",
            fontFamily: 'Roboto',
            background: " #F6F6F6",
            color: "rgba(132, 132, 132, 1)",
            borderRadius: "4px",
            borderColor: "#979797",
            "& .MuiOutlinedInput-input": {
                paddingTop: "20px",
                paddingBottom: "20px",
            },
            "& .MuiSelect-nativeInput": {
                opacity: 1,
                background: "#F2F2F2",
                border: "none",
                fontSize: "16px",
                color: 'rgba(132, 132, 132, 1)',
                padding: "20px",
                fontFamily: 'Roboto',
                fontWeight: "Regular",
            },
            "& .MuiSelect-select.MuiSelect-select ": {
                background: "#F2F2F2",
            },
            "& fieldset": {
                borderRadius: "4px",
                fontWeight: 400,
            },
        },
        "& ::placeholder": {
            opacity: 1,
        }
    }
})(TextField);

const CandidateDatePicker: any = withStyles({
    root: {
        width: '100%',
        "& .MuiInputBase-root": {
            background: "#F6F6F6",
            width: "100%",
            borderRadius: "4px",
            paddingLeft: "15px",
            marginTop: "-7px",
            fontFamily: 'Roboto',
            fontWeight: "Regular",
            height: "57px",
            border: "1px solid #979797",

        }
    }
})
//Customizable Area End
