import React from "react";
// Customizable Area Start
import { Formik } from "formik";
import {
  Box,
  Button, MenuItem, InputLabel,
  Typography,
  InputBase,
  TextField,
  Snackbar,
  InputAdornment,
  Select
} from "@material-ui/core";
import * as Yup from 'yup'
import Popover from '@material-ui/core/Popover';
import MuiAlert from '@material-ui/lab/Alert';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import HeaderWeb from "../../../components/src/Header.web";
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

const priority_level = [
  {
    value: 'Strong submission',
  },
  {
    value: 'Urgent',
  },
  {
    value: 'Caution',
  },
];

const clearance_level = [
  {
    value: 'TS/SCI',
  },
  {
    value: 'TS/SCI CI Poly',
  },
  {
    value: 'TS/SCI FSP ',
  },
  {
    value: 'Public Trust',
  },
  {
    value: 'Secret',
  },
  {
    value: 'Top secret',
  },

];


const city = [

  {
    value: 'Surat',
  },
  {
    value: 'Ahemdabad',
  },
  {
    value: 'Morbi',
  },
  {
    value: 'Rajkot',
  },
];

const state = [

  {
    value: 'Bihar',
  },
  {
    value: 'Maharastra',
  },
  {
    value: 'gujarat',
  },
  {
    value: 'Rajsthan',
  },
];

const configJSON = require("./config");

// Customizable Area End

import CurrentJobOpeningBlockController, {
  Props
} from "./CurrentJobOpeningBlockController.web";
/* istanbul ignore next */

export default class CurrentJobOpeningBlock extends CurrentJobOpeningBlockController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End


  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Startonf
    return (
      //Required for all blocks
      <>
        <HeaderWeb />
        <MainComponent
          state={this.state}
          openPopover={this.openPopover}
          closePopover={this.closePopover}
          handlePostdata={this.handlePostdata}
          finalErrorMessage={this.finalErrorMessage}
          handleJobsearch={this.handleJobsearch}
        />
      </>

    );

    // Customizable Area End
  }
}
// Customizable Area Start
/* istanbul ignore next */

function MainComponent(props: any) {
  const classes = useStyles();
  return (
    <>
      <Box style={webStyle.pageContainer} >
        <Typography style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Current Job Openings</Typography>
        <Box style={{ width: '100%', display: 'grid', marginTop: '10px', gridTemplateColumns: '8fr 2fr', gap: '0.5rem' }}>

          <div style={{
            display: 'flex',
            background: '#F2F2F2',
            alignItems: 'center',
            border: '1px solid #e1e1e1',
            borderRadius: "6px",
            height: 50
          }}>
            <SearchIcon style={{
              padding: "10px",
              textAlign: "center",
              marginLeft: '15px'
            }} />
            <Input
              type="text"
              value={props.state.jobSearch}
              onChange={props.handleJobsearch}
              placeholder='Search job openings'
            />
          </div>
          <Button onClick={!props.state.popoverStatus ? (e) => props.openPopover(e) : () => props.closePopover()} style={{ zIndex: 0, backgroundColor: "red", width: '14.5rem', height: '50px', color: 'white', textTransform: 'none' }}>Add Job</Button>
          {props.state.popoverStatus && <Box style={{
            display: 'flex', zIndex: 2, paddingTop: '-4em', marginTop: '-43px', marginRight: '-8.8em'
            , justifyContent: 'flex-end',
          }}>
            <Box style={newStyle.triangle} /></Box>}
          <Popover
            open={props.state.popoverStatus}
            anchorEl={props.state.popoverRef}
            onClose={() => props.closePopover()}
            className="addjob-popover"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box style={newStyle.main}>
              <Typography style={newStyle.headding}>
                {" "}
                Add New Job Opening
              </Typography>
              <Formik
                validateOnChange
                validateOnBlur
                initialValues={{
                  company_name: '',
                  position: "",
                  category: '',
                  clearance_level: '',
                  priority_level: '',
                  min_salary: '',
                  max_salary: '',
                  select_state: '',
                  select_city: '',
                  title: '',
                  name: '',
                  department: '',
                  email: '',
                  phone_number: '',
                }}

                validationSchema={Yup.object().shape({
                  company_name: Yup.string().required('Company name is required')
                    .matches(
                      /[a-zA-Z]/,
                      'Insert at least one character'
                    ),
                  position: Yup.string()
                    .required('Position is required')
                    .matches(
                      /[a-zA-Z]/,
                      'Insert at least one character'
                    ),
                  category: Yup.number().required('Category is required').positive('Category is required'),
                  clearance_level: Yup.string()
                    .required('Clearance level is required')
                    .matches(
                      /[a-zA-Z]/,
                      'Insert at least one character'
                    ),
                  priority_level: Yup.string()
                    .required('Priority level is required')
                    .matches(
                      /[a-zA-Z]/,
                      'Insert at least one character'
                    ),
                  min_salary: Yup.string().required('Min Salary is required'),
                  max_salary: Yup.string().required('Max Salary is required'),
                  select_state: Yup.string().required('State is required'),
                  select_city: Yup.string().required('City is required'),
                  title: Yup.string().required('Title is required'),
                  name: Yup.string().required('Name is required').matches(
                    /[a-zA-Z]/,
                    'Insert at least one character'
                  ),
                  department: Yup.string().required('Department is required'),
                  email: Yup.string().required('Email is required').matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, 'Invalid Email'),
                  phone_number: Yup.string()
                    .required('Phone number is required')
                    .matches(configJSON.phoneNumberValidation, 'Please enter a valid number')
                })}
                onSubmit={(values: any, actions: any) => {
                  props.handlePostdata(values).then(() => {
                    actions.setSubmitting(false);
                    actions.resetForm({
                      values: {
                        company_name: '',
                        position: "",
                        category: '',
                        clearance_level: '',
                        priority_level: '',
                        min_salary: '',
                        max_salary: '',
                        select_state: '',
                        select_city: '',
                        title: '',
                        name: '',
                        department: '',
                        email: '',
                        phone_number: '',
                      }
                    })
                  });
                }}
              >
                {({ errors, values, touched, handleSubmit, handleChange, handleBlur, setFieldValue }: any) => (

                  <form onSubmit={handleSubmit}>
                    <div >
                      <TextField
                        style={newStyle.compentNametxtfiled}
                        name="company_name"
                        InputProps={{ classes }}
                        placeholder="Add company name"
                        onChange={handleChange}
                        value={values.company_name}
                        error={errors.company_name && touched.company_name}
                      />
                      <div style={{ marginLeft: 20, marginTop: -10 }}>

                        {props.finalErrorMessage(errors.company_name, touched.company_name)}
                      </div>
                    </div>

                    <div style={newStyle.position}>
                      <div style={{ minWidth: '46%', marginRight: 20 }}>
                        <InputField
                          id="filled-select-currency-native"
                          name="position"
                          placeholder="Position"
                          InputProps={{ classes }}
                          onChange={handleChange}
                          value={values.position}
                          variant="filled"
                          error={errors.position && touched.position}
                        />
                        {props.finalErrorMessage(errors.position, touched.position)}
                      </div>
                      <div style={{ minWidth: '46%' }}>
                        <Select
                          displayEmpty
                          id="demo-controlled-open-select"
                          value={values.category}
                          name='category'
                          onChange={handleChange}
                          input={<SelectInput id="select-multiple-placeholder" />}
                          renderValue={() => values.category ? props.state.categoryDetails.map((item: any) => item.attributes.id === values.category && item.attributes.name) : 'Category'}
                        >
                          <MenuItem value="placeholder" disabled>
                            Category
                          </MenuItem>
                          {props.state.categoryDetails.map((item: any) => (
                            <MenuItem
                              key={item.attributes.id}
                              value={item.attributes.id}
                            >
                              {item.attributes.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {props.finalErrorMessage(errors.category, touched.category)}
                      </div>
                    </div>
                    <div style={newStyle.position}>
                      <div style={{ minWidth: '46%', marginRight: 20 }}>
                        <InputField
                          id="filled-select-currency-native"
                          select
                          name="clearance_level"
                          InputProps={{ disableunderline: true }}
                          placeholder="Choose clearance"
                          error={errors.clearance_level && touched.clearance_level}
                          onChange={handleChange}
                          variant="filled"
                        >
                          {clearance_level.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </InputField>
                        {props.finalErrorMessage(errors.clearance_level, touched.clearance_level)}
                      </div>
                      <div style={{ minWidth: '46%' }}>
                        <InputField
                          id="filled-select-currency-native"
                          select
                          name="priority_level"
                          InputProps={{ disableunderline: true }}
                          placeholder="Select priority level"
                          error={errors.priority_level && touched.priority_level}
                          onChange={handleChange}
                          variant="filled"
                        >
                          {priority_level.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </InputField>
                        {props.finalErrorMessage(errors.priority_level, touched.priority_level)}
                      </div>

                    </div>

                    <Box>
                      <Box style={newStyle.salaryArea}>
                        <Box>
                          <InputLabel style={newStyle.salarylable}>
                            Min Salary :
                          </InputLabel>
                          <Input
                            name="min_salary"
                            placeholder="Enter min Salary"
                            InputProps={{ classes }}
                            onChange={handleChange}
                            value={values.min_salary}
                            error={errors.min_salary && touched.min_salary}
                          />
                          {props.finalErrorMessage(errors.min_salary, touched.min_salary)}

                        </Box>

                        <Box style={newStyle.minsaler2}>
                          <InputLabel style={newStyle.salarylable}>
                            Max Salary :
                          </InputLabel>
                          <Input
                            name="max_salary"
                            placeholder="Enter max Salary"
                            InputProps={{ classes }}
                            onChange={handleChange}
                            value={values.max_salary}
                            error={errors.max_salary && touched.max_salary}
                          />
                          {props.finalErrorMessage(errors.max_salary, touched.max_salary)}

                        </Box>
                      </Box>
                    </Box>
                    <div style={newStyle.adressmain}>
                      <InputLabel style={newStyle.adreslbale}>Address :</InputLabel>

                      <Box style={newStyle.adreestextArea}>
                        <div style={{ minWidth: '46%', marginRight: 20 }}>

                          <InputField
                            id="filled-select-currency-native"
                            select
                            name="select_state"
                            InputProps={{ disableunderline: true }}
                            placeholder="Select State"
                            disableunderline={false}
                            onChange={handleChange}
                            error={errors.select_state && touched.select_state}
                            variant="filled"
                          >
                            {state.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </InputField>
                          {props.finalErrorMessage(errors.select_state, touched.select_state)}
                        </div>
                        <div style={{ minWidth: '46%' }}>

                          <InputField
                            id="filled-select-currency-native"
                            select
                            name="select_city"
                            placeholder="Select City"
                            InputProps={{ disableunderline: true }}
                            onChange={handleChange}
                            error={errors.select_city && touched.select_city}
                            variant="filled"
                            values={values.select_city}
                            onBlur={handleBlur}
                          >
                            {city.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </InputField>
                          {props.finalErrorMessage(errors.select_city, touched.select_city)}
                        </div>
                      </Box>
                    </div>
                    <Typography style={newStyle.asigeedtext}> Assinged To :</Typography>
                    <Box style={newStyle.persnaldetail}>
                      <div style={{ minWidth: '20%', marginRight: 20 }}>

                        <InputField
                          id="filled-select-currency-native"
                          select
                          name="title"
                          placeholder="Title"
                          InputProps={{ disableunderline: true }}
                          onChange={handleChange}
                          error={errors.title && touched.title}
                          variant="filled"
                          values={values.title}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="Mr.">Mr.</MenuItem>
                          <MenuItem value="Ms.">Ms.</MenuItem>
                          <MenuItem value="Dr.">Dr.</MenuItem>
                        </InputField>
                        {props.finalErrorMessage(errors.title, touched.title)}
                      </div>
                      <div style={{ minWidth: '35%', marginRight: 20 }}>

                        <InputField
                          id="filled-select-currency-native"
                          name="name"
                          placeholder="Enter name"
                          InputProps={{ classes }}
                          onChange={handleChange}
                          value={values.name}
                          variant="filled"
                          error={errors.name && touched.name}
                        />
                        {props.finalErrorMessage(errors.name, touched.name)}
                      </div>
                      <div style={{ minWidth: '34%' }}>
                        <InputField
                          id="filled-select-currency-native"
                          select
                          name="department"
                          placeholder="Department"
                          InputProps={{ disableunderline: true }}
                          onChange={handleChange}
                          error={errors.department && touched.department}
                          variant="filled"
                          values={values.department}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="Technichal">Technichal</MenuItem>
                          <MenuItem value="Geology">Geology</MenuItem>
                        </InputField>
                        {props.finalErrorMessage(errors.department, touched.department)}
                      </div>
                    </Box>
                    <div style={newStyle.detail}>
                      <div style={{ minWidth: '46%', marginRight: 20 }}>
                        <Input
                          name="email"
                          placeholder="Enter email"
                          InputProps={{ classes }}
                          style={{ marginRight: 10 }}
                          onChange={handleChange}
                          value={values.email}
                          error={errors.email && touched.email}
                        />
                        {props.finalErrorMessage(errors.email, touched.email)}
                      </div>
                      <div style={{ minWidth: '46%' }}>
                        <Input
                          name="phone_number"
                          placeholder="Enter contact number"
                          InputProps={{ classes }}
                          style={{ marginRight: 10 }}
                          onChange={handleChange}
                          value={values.phone_number}
                          error={errors.phone_number && touched.phone_number}
                        />
                        {props.finalErrorMessage(errors.phone_number, touched.phone_number)}
                      </div>
                    </div>

                    <Box style={newStyle.bootomButtonArea}>
                      <Button
                        style={newStyle.bootomButton}
                        type="submit"
                      >

                        Add New Job Opening
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>

            </Box>


          </Popover>
        </Box>
        <Box> {props.state.currntJobData.map((data: any, index: any) => {
          return (
            <Box style={{ margin: '1rem 0rem 0rem 0rem ', marginTop: '20px' }} key={index}>
              <Box style={webStyle.jobContainer}>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box>
                    <Box sx={{ fontSize: '1.2rem', fontFamily: 'Roboto-Medium', margin: '0.5rem 0rem', }} style={webStyle.bold}>
                      {data.position}
                    </Box>
                    <Box style={{ display: 'flex', marginTop: 10, marginBottom: 10 }}>
                      <Box style={webStyle.flexRowCenter}><BusinessOutlinedIcon fontSize="small" /><Typography style={{ fontSize: '0.9rem', fontFamily: 'Roboto-Medium', margin: "0rem 0.25rem" }}>{data.company_name}</Typography></Box>
                      <Box style={webStyle.flexRowCenter}><LocationOnOutlinedIcon fontSize="small" /> <Typography style={{ fontSize: '0.9rem', fontFamily: 'Roboto-Medium', margin: "0rem 0.25rem" }}>{data.state} , {data.city}</Typography></Box>
                    </Box>
                  </Box>
                  <Box style={{ backgroundColor: '#50A8EA', color: 'white', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: '1rem', height: '3rem', border: '1px solid', width: '12rem', fontFamily: 'Roboto-Medium' }}>{data.priority_level}</Box>
                </Box>
                <Typography style={{ fontSize: '1.1rem', fontWeight: 'bold', fontFamily: 'Roboto-Medium', marginBottom: 10 }}>{String(data.min_sal).replace(/"$|^"/g, '')} - {String(data.max_sal).replace(/"$|^"/g, '')} </Typography>
                <Typography style={{ fontSize: '0.85rem', color: 'gray', fontFamily: 'Roboto-Medium' }}>{data.description ? data.description.replace(/<\/?p>/g, "") : ""}</Typography>
                <hr style={{ margin: '0.5rem 0rem', color: '#e1e1e1' }} />
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box style={{ display: 'flex', width: '27 rem', justifyContent: 'start', alignItems: 'start', }}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 40 }}><Typography style={{ color: '#6200EE' }}>Submitted : {data.submitted}</Typography></Box>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 40 }}><Typography style={{ color: '#6200EE' }}>Clearance : {data.clearance}</Typography></Box>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginRight: 40 }}><CalendarTodayOutlinedIcon fontSize="small"
                    /><Typography style={{ fontSize: '0.9rem', fontFamily: 'Roboto-Medium', margin: "0rem 0.25rem" }}>Available-dates{data.avalaible_dates}</Typography></Box>
                  </Box>
                  <Box style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', color: '#6200EE' }}><EditOutlinedIcon fontSize="small" /> <Typography style={{ fontSize: '0.9rem', fontFamily: 'Roboto-Medium', margin: "0rem 0.25rem" }}>Edit</Typography></Box>
                </Box>
              </Box>
            </Box>)
        }

        )}

        </Box>
        <Snackbar autoHideDuration={2000} anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
          open={props.state.errorOpenNotification} >
          <MuiAlert elevation={6} variant="filled" severity={props.state.isError ? 'error' : 'success'} >
            {props.state.messageText}
          </MuiAlert>
        </Snackbar>
      </Box>
    </>
  )
}
const newStyle: any = {
  inputicons: {
    display: 'flex',
    alignItems: 'center',
  },

  inputIcons: {
    width: "100 %",
    marginBottom: "10px",

  },

  icon: {
    padding: "10px",
    minWidth: "50px",
    textAlign: "center",
    marginLeft: '15px'
  },

  inputField: {
    width: "100%",
    padding: "10px",
    textAlign: "center"
  },
  header: {
    padding: '1rem',
    borderRadius: '4px',
    "& .MuiInputBase-inputAdornedStart": {
      marginTop: 0
    }
  },
  main: {
    borderRadius: "8px",
    width: "731px",
    height: '550px',
    backgroundColor: '#FFFF',
  }, triangle: {
    color: "#F2F2F2",

    position: 'relative',
    height: '14px', zIndex: 2, width: 0,
    borderBottom: '25px solid #131313',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',

  },
  asigeedtext: {
    marginLeft: '20px',
    fontWeight: 'bold',
    fontSize: '15px'
  },
  headding: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "30px",
    marginLeft: "18px",
    border: "none",
  },

  compentNametxtfiled: {
    minWidth: '85%',
    padding: '17px',
    background: "#F2F2F2",
    borderRadius: "8px",
    marginBottom: "20px",
    marginLeft: '18px',
    marginTop: "20px",
    marginRight: '18px',
    border: "none",
  },
  card: {
    display: "flex",
    border: "none",
    textTransform: 'lowercase'
  },
  position: {
    display: "flex",
    margin: "20px",
    border: "none",
    marginTop: "-10px"
  },
  adressmain: {
    justifyContent: "center",
    margin: "20px"
  },
  FormControl1: {
    width: "45%",
    padding: '14px',
    background: "#F2F2F2", color: '#929292',
    border: "none",
    fontSize: '12px',

  },
  FormControl2: {
    width: "45%",
    padding: '14px',
    marginLeft: "20px",
    background: "#F2F2F2", color: '#929292',
    border: "none",
    fontSize: '12px',

  },
  SelcedItem: {
    fontSize: '10px',
    color: "black",
  },
  FormControl3: {
    width: "50%",
    height: "50px", color: '#929292',
  },
  FormControl10: {
    width: "45%",
    padding: '17px',
    background: "#F2F2F2",
    color: '#929292',
    border: "none",
  },
  FormControl9: {
    minWidth: "45%",
    paddingLeft: '14px',
    marginLeft: "20px",
    marginRight: 18,
    background: "#F2F2F2",
    color: '#929292',
    fontSize: '12px'
  },
  FormControl8: {
    width: "45%",
    padding: '14px',
    background: "#F2F2F2", color: '#929292',
    fontSize: '12px'
  },
  FormControl7: {
    width: "45%",
    padding: '14px',
    fontSize: '12px',
    background: "#F2F2F2", color: '#929292',
    marginLeft: "20px"
  },
  adreslbale: {
    fontSize: "15px",
    border: "none",
    fontWeight: "bold",
    color: "black"
  },
  adreestextArea: {
    display: "flex",
    marginTop: "10px"
  },
  salaryArea: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    margin: "20px"
  },
  salarylable: {
    fontSize: "15px",
    fontWeight: "bold",
    color: "black",
    marginBottom: '10px'
  },
  minsalery: {
    width: "50x",
    border: "none",
    padding: '17px',
    background: "#F2F2F2",

    marginTop: "5px"
  },
  minsaler2: {
    marginLeft: "30px"
  },
  maxsalery: {
    width: "50x",
    padding: '17px',
    border: "none",
    background: "#F2F2F2",
    marginTop: "5px"
  },


  feild1: {
    background: "#F2F2F2",
    minWidth: "45%",
    padding: '17px',
    border: "none"
  },
  feild2: {
    background: "#F2F2F2",
    minWidth: "45%",
    padding: '17px',
    marginLeft: "20px",
    border: "none",
    margin: "10px"
  },
  persnaldetail: {
    display: "flex",
    margin: "20px"
  },

  title: {
    background: "#F2F2F2", color: '#929292',
    width: "85px",
    padding: '14px',
    fontSize: '12px'
  },
  namedetail: {
    background: "#F2F2F2",
    padding: '17px',
    width: "38%",
    border: "none",
    marginLeft: "10px"
  },

  bootomButtonArea: {
    width: "100%",
    textTransform: 'capitalize',

    justifyContent: "center",
    margin: "10px"
  },
  bootomButton: {
    backgroundColor: "red",
    color: "white",
    textTransform: 'capitalize',
    minWidth: "90%",
    margin: "10px",
    border: "none",
    justifyContent: "center"
  },
  personadetail: {
    display: "flex",
    margin: "20px",
    border: "none"
  },
  detail: {
    display: 'flex',
    margin: "20px"
  },

  clesaser: {
    display: "flex",
    margin: "20px",
    MinWidth: "100%",
    border: "none"
  }
};
const SelectInput: any = withStyles({
  root: {
    width: '100%',
    "& .MuiInputBase-input": {
      background: "#F2F2F2",
      width: "100%",
      borderRadius: "4px",
      marginTop: "13px",
      fontFamily: 'Roboto',
      fontWeight: "Regular",
      height: "20px",
      padding: "20px",
      color: "rgba(132, 132, 132, 1)",
    },
    "& .MuiSvgIcon-root": {
      color: "black",
      paddingTop: "12px",
    }
  }
})(InputBase);
const InputField: any = withStyles({
  root: {
    width: '100%',
    "& .MuiFilledInput-root": {
      fontWeight: "Regular",
      marginTop: "13px",
      fontFamily: 'Roboto',
      background: "#F2F2F2",
      color: "rgba(132, 132, 132, 1)",
      borderRadius: "4px",
      border: "none",
      "& .MuiFilledInput-input": {
        paddingTop: "20px",
        paddingBottom: "20px",
      },
      "& .MuiSelect-nativeInput": {
        opacity: 1,
        background: "#F2F2F2",
        border: "none",
        fontSize: "16px",
        color: 'rgba(132, 132, 132, 1)',
        padding: "20px",
        fontFamily: 'Roboto',
        fontWeight: "Regular",
      },
      "& .MuiSelect-iconFilled": {
        color: "black",
      },
      "& .MuiSelect-select.MuiSelect-select ": {
        background: "#F2F2F2",
      },
      "& fieldset": {
        borderRadius: "4px",
        fontWeight: 400,
      },
    },
    "& ::placeholder": {
      opacity: 1,
    }
  }

})(TextField);

const Input: any = withStyles({
  root: {
    width: '100%',
    "& .MuiInputBase-input": {
      background: "#F2F2F2",
      width: "100%",
      borderRadius: "4px",
      paddingLeft: "36px",
      fontFamily: 'Roboto',
      fontWeight: "Regular",
      height: "45px",
      boxSizing: 'border-box'
    }
  }
})(InputBase);

const webStyle: any = {
  pageContainer: {
    padding: '1rem 2rem',
    width: '100vw',
    marginTop: '20px',
    overFlow: 'scroll',
    minHeight: '700px'
  },
  jobContainer: {
    border: '1px solid #e1e1e1',
    padding: '0.5rem 0.75rem 0.5rem 1rem',
    width: '90%',
    borderRadius: '0.5rem',
    background: '#F2F2F2',
    marginTop: '10px'
  },
  bold: {
    fontWeight: '590',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '30px'
  }
  ,
  card: {
    background: "#FFFF",
    borderRadius: "8px",
    marginTop: "44px",
    padding: "0px 88px 125px 125px"
  },
}
// Customizable Area End
