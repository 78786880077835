import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import flatpickr from 'flatpickr';
import { ApiCall } from '../../../../components/src/ApiCall.web';
// Customizable Area Start
export const configJSON = require("./../config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    apiToken: any,
    isGraph: boolean;
    setValue: string;
    setPage: number;
    pageSize: number;
    rows: any[];
    pageCount: number;
    AdHocCountingData: any;
    AdHocProgressData: any;
    AdHocGraphData: any;
    isCheckedCurrentJobOpening: string;
    isCheckedActiveCandidate: string;
    isCheckedInactiveCandidate: string;
    isCheckedSubmitCandidates: string;
    isCheckedClearanceLable: string;
    isCheckedInterviewWithClient: string;
    isCheckedCandidateExpectingOffer: string;
    isCheckedJobsBySubCategory: string;
    isCheckedCandidatesAccepted: string;
    isCheckedCandidatesSubmittedAndWaitingForClient: string;
    // Customizable Area End
}
interface SS {
}

export default class AdHocReportingController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAdHocReportingCountAPICallID: any;
    getAdHocReportingGraphAPICallID: any;
    getAdHocReportingProgressAPICallID: any;
    // Customizable Area End


    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            apiToken: "",
            isGraph: false,
            setValue: "weekly",
            setPage: 1,
            pageSize: 5,
            rows: [],
            pageCount: 1,
            AdHocCountingData: {},
            AdHocProgressData: {},
            AdHocGraphData: [],
            isCheckedCurrentJobOpening: "current_job_opening",
            isCheckedActiveCandidate: "",
            isCheckedInactiveCandidate: "",
            isCheckedSubmitCandidates: "",
            isCheckedClearanceLable: "",
            isCheckedInterviewWithClient: "",
            isCheckedCandidateExpectingOffer: "",
            isCheckedJobsBySubCategory: "",
            isCheckedCandidatesAccepted: "",
            isCheckedCandidatesSubmittedAndWaitingForClient: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentWillUnmount() {
        super.componentWillUnmount();
    }

    async componentDidMount() {
        const token = (await localStorage.getItem("token")) || "";
        this.setState({ apiToken: token })
        super.componentDidMount();
        this.handleDatePicker();
        this.handleAdHocReportingCountGetApi();
        this.handleAdHocReportingProgressGetApi();
        this.handleAdHocReportingGraphGetApi("current_job_opening");
    }
    inputRef = React.createRef();

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
            else if (this.isInValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            }
            else if (errorReponse) {
                alert("Internal Server Error.")
            }
            if (responseJson.status === 500) {
                console.log(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    isValidResponse = (responseJson: any) => {
        return responseJson
    };
    // Customizable Area End

    // Customizable Area Start
    isInValidResponse = (responseJson: any) => {
        return responseJson || responseJson.errors;
    };
    // Customizable Area End


    // Customizable Area Start
    handleAdHocReportingCountGetApi = async () => {
        this.getAdHocReportingCountAPICallID = await ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.AdHocCountGetApiEndPoint,
        });
    }
    // Customizable Area End

    handleParams = async () => {
        const { isCheckedActiveCandidate, isCheckedCandidateExpectingOffer, isCheckedCandidatesAccepted, isCheckedClearanceLable, isCheckedCandidatesSubmittedAndWaitingForClient, isCheckedCurrentJobOpening, isCheckedInactiveCandidate, isCheckedInterviewWithClient, isCheckedJobsBySubCategory, isCheckedSubmitCandidates } = this.state
        const params = new URLSearchParams()
        isCheckedCurrentJobOpening && params.append("current_job_opening", isCheckedCurrentJobOpening)
        isCheckedActiveCandidate && params.append("active_candidates", isCheckedActiveCandidate)
        isCheckedInactiveCandidate && params.append("inactive_candidates", isCheckedInactiveCandidate)
        isCheckedSubmitCandidates && params.append("submitted_candidates", isCheckedSubmitCandidates)
        isCheckedClearanceLable && params.append("clearance_level", isCheckedClearanceLable)
        isCheckedInterviewWithClient && params.append("interview_with_client", isCheckedInterviewWithClient)
        isCheckedCandidateExpectingOffer && params.append("candidates_expecting_offer", isCheckedCandidateExpectingOffer)
        isCheckedJobsBySubCategory && params.append("candidates_accepted", isCheckedJobsBySubCategory)
        isCheckedCandidatesAccepted && params.append("candidates_accepted", isCheckedCandidatesAccepted)
        isCheckedCandidatesSubmittedAndWaitingForClient && params.append("active_candidates", isCheckedCandidatesSubmittedAndWaitingForClient)
        return params
    }

    // Customizable Area Start
    handleAdHocReportingGraphGetApi = async (parameter: any) => {
        if (parameter) {
            let url = new URLSearchParams();
            if (parameter === "clearance_level") {
                url.append(parameter, "Public Trust");
            } else {
                url.append(parameter, parameter);
            }

            this.getAdHocReportingGraphAPICallID = await ApiCall({
                contentType: configJSON.dashboarContentType,
                method: configJSON.dashboarApiMethodType,
                endPoint: configJSON.AdHocGraphGetApiEndPoint + `?${url}`,
            });
        }
    }
    // Customizable Area End


    // Customizable Area Start
    handleAdHocReportingProgressGetApi = async () => {
        const params = await this.handleParams()

        console.log(params, "@@@ 2nd params")
        this.getAdHocReportingProgressAPICallID = await ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.AdHocProgressGetAPIEndPoint + `?${params}`,
        });
    }
    // Customizable Area End


    // Customizable Area Start
    apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getAdHocReportingCountAPICallID) {
            this.getAdHocReportingCountSuccess(responseJson);
        }
        if (apiRequestCallId === this.getAdHocReportingProgressAPICallID) {
            this.getAdHocReportingProgressSuccess(responseJson);
        }
        if (apiRequestCallId === this.getAdHocReportingGraphAPICallID) {
            this.getAdHocReportingGraphSuccess(responseJson);
        }
    };
    // Customizable Area End

    // Customizable Area Start
    apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getAdHocReportingCountAPICallID) {
            this.getAdHocReportingCountFailuerCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAdHocReportingProgressAPICallID) {
            this.getAdHocReportingProgressFailuerCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAdHocReportingGraphAPICallID) {
            this.getAdHocReportingGraphFailuerCallBack(responseJson);
        }
    };
    // Customizable Area End

    // Customizable Area Start
    getAdHocReportingCountSuccess = (res: any) => {
        this.setState({ AdHocCountingData: res })
    }
    getAdHocReportingCountFailuerCallBack = (data: any) => {
        console.log(data, "errorMessage")
    }
    // Customizable Area End


    // Customizable Area Start
    getAdHocReportingProgressSuccess = (res: any) => {
        this.setState({ AdHocProgressData: res })
    }
    getAdHocReportingProgressFailuerCallBack = (data: any) => {
        console.log(data, "errorMessage")
    }
    // Customizable Area End


    // Customizable Area Start
    getAdHocReportingGraphSuccess = (res: any) => {
        this.setState({ AdHocGraphData: res?.graph_data, rows: res?.TableData }, () => {
            this.handlePageCount()
            console.log(this.state.AdHocGraphData, "@@@ === AdHocGraphData")
        })
    }
    getAdHocReportingGraphFailuerCallBack = (data: any) => {
        alert(data)
    }

    handlePageCount = () => {
        console.log(this.state?.rows, "!@")
        const pageCount1 = Math.ceil(this.state?.rows?.length / this.state.pageSize);
        this.setState({ pageCount: pageCount1 })
    }

    handlePageChange = (value: any) => {
        this.setState({ setPage: value });
    };
    // Customizable Area End


    // Customizable Area Start
    handleDatePicker = () => {
        flatpickr("#dateRangePicker", {
            enableTime: false,
            mode: "range",
            dateFormat: "m/d/Y",
            minDate: "today",
            position: "auto center",
            allowInput: true,
            onChange: this.handleDateOnChange,
        });
    }

    handleDateOnChange = (dateStr: any) => {
        console.log("@@__", dateStr);
    }
    // Customizable Area END

    // Customizable Area Start
    handleOpenGraph = () => {
        this.setState({
            isGraph: !this.state.isGraph,
        })
    }
    // Customizable Area END

    // Customizable Area Start
    handleCurrentJobOpeningCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedCurrentJobOpening: event.target.value });
        }
        else {
            this.setState({ isCheckedCurrentJobOpening: "" });
        }
    };
    // Customizable Area END


    // Customizable Area Start
    handleActiveCandidateCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedActiveCandidate: event.target.value });
        }
        else {
            this.setState({ isCheckedActiveCandidate: "" });
        }
    };
    // Customizable Area END handleInactiveCandidateOnChange

    // Customizable Area Start
    handleInactiveCandidateCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedInactiveCandidate: event.target.value });
        }
        else {
            this.setState({ isCheckedInactiveCandidate: "" });
        }
    };
    // Customizable Area END

    // Customizable Area Start
    handleSubmitCandidatesCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedSubmitCandidates: event.target.value });
        }
        else {
            this.setState({ isCheckedSubmitCandidates: "" });
        }
    };
    // Customizable Area END

    // Customizable Area Start
    handleClearanceLableCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedClearanceLable: event.target.value });
        }
        else {
            this.setState({ isCheckedClearanceLable: "" });
        }
    };
    // Customizable Area END

    // Customizable Area Start
    handleInterviewWithClientCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedInterviewWithClient: event.target.value });
        }
        else {
            this.setState({ isCheckedInterviewWithClient: "" });
        }
    };
    // Customizable Area END

    // Customizable Area Start
    handleCandidateExpectingOfferCkeckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedCandidateExpectingOffer: event.target.value });
        }
        else {
            this.setState({ isCheckedCandidateExpectingOffer: "" });
        }
    };
    // Customizable Area END

    // Customizable Area Start
    handleJobsBySubCategoryCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedJobsBySubCategory: event.target.value });
        }
        else {
            this.setState({ isCheckedJobsBySubCategory: "" });
        }
    };
    // Customizable Area END

    // Customizable Area Start
    handleCandidatesAcceptedCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedCandidatesAccepted: event.target.value });
        }
        else {
            this.setState({ isCheckedCandidatesAccepted: "" });
        }

    };
    // Customizable Area END

    // Customizable Area Start
    handleCandidatesSubmittedAndWaitingForClientCheckBox = (event: any) => {
        if (event.target?.checked) {
            this.setState({ isCheckedCandidatesSubmittedAndWaitingForClient: event.target.value });
        }
        else {
            this.setState({ isCheckedCandidatesSubmittedAndWaitingForClient: "" });
        }
    };
    // Customizable Area END


    // Customizable Area Start
    handleRadioOnChange = (event: any) => {
        this.setState({ setValue: event.target?.value }, () => {
            console.log(this.state.setValue, "===@@@");
        });
    };

    getData = (data: any) => {
        if(data <= 9){
            return 0 + data
        }
        else{
           return data
        }
    } 
    // Customizable Area END
}
