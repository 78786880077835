import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { apiCall, checkSession, isLoggedIn } from "../../../components/src/Common";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: any;
  name: any;
  password: any;
  phoneNumber: any;
  initialCreds: any;
  initialDatas: any;
  pageLoading: boolean;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  theme: any;
  editorHtml: any;
  currentPasswordText: any;
  newPasswordText: any;
  currency: any;
  priorityLevel: any;
  state: any;
  city: any;
  category: any;
  min_salary: any;
  max_salary: any;
  openNotification: boolean;
  isDetails: any;
  categoryDetails: any;
  errorOpenNotification: boolean,
  isError: boolean,
  messageText: string
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  passwordReg: RegExp;
  emailReg: RegExp;
  authToken: any;
  apiPostCreationCallId: any;
  getCategoryData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      email: "",
      password: "",
      name: "",
      isDetails: "",
      phoneNumber: "",
      initialCreds: {
        current_password: "",
        new_password: "",
        // email: '',
      },
      categoryDetails: [],
      initialDatas: {
        company_name: "",
        position: "",
        clearance: "",
        priority_level: "",
        state: "",
        city: "",
        category_id: -1,
        account_id: "",
        description: "",
        min_salary: '',
        max_salary: '',
      },
      data: [],
      passwordHelperText: "",
      pageLoading: false,
      currency: "",
      priorityLevel: "",
      category: "",
      state: "",
      city: "",
      enablePasswordField: true,
      theme: true,
      min_salary: '',
      max_salary: '',
      editorHtml: "",
      currentPasswordText: "",
      newPasswordText: "",
      openNotification: false,
      errorOpenNotification: false,
      isError: false,
      messageText: ''
    };


    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.checkMainResponse({ apiRequestCallId, responseJson, errorReponse })
    // Customizable Area End
  }



  // Customizable Area Start

  checkMainResponse({ apiRequestCallId, responseJson, errorReponse }: any) {
    /* istanbul ignore next */
    if (apiRequestCallId && responseJson) {
      this.checkGetCatResponse({ apiRequestCallId, responseJson, errorReponse })
      this.checkPostCreateResponse({ apiRequestCallId, responseJson, errorReponse })

    }
  }
  finalErrorMessage(error: any, touch: any) {
    /* istanbul ignore next */
    if (error && touch) {
      /* istanbul ignore next */
      return <div style={{
        color: "red",
        fontSize: "14px",
        margin: "5px 5px 5px 0px"
      }}>
        {error}
      </div>

    }
  }
  commonErrorSection({ responseJson }: any) {
    /* istanbul ignore next */
    if (
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      responseJson.errors[0].token
    ) {
      this.showAlert("Session Expired", "Please Log in again.");
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  checkGetCatResponse({ apiRequestCallId, responseJson, errorReponse }: any) {
    /* istanbul ignore next */
    if (apiRequestCallId === this.getCategoryData) {
      checkSession(responseJson, this.props.navigation)
      /* istanbul ignore next */
      if (
        !responseJson.errors &&
        responseJson.data
      ) {
        //On User Profile Success
        /* istanbul ignore next */
        this.setState({ categoryDetails: responseJson.data })
      } else {
        //Check Error Response
        this.commonErrorSection({ errorReponse })
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  checkPostCreateResponse({ apiRequestCallId, responseJson, errorReponse }: any) {
    /* istanbul ignore next */
    if (apiRequestCallId === this.apiPostCreationCallId) {
      /* istanbul ignore next */
      if (!responseJson.error && responseJson) {
        //On current job Success
        /* istanbul ignore next */
        this.setState({
          isError: false,
          errorOpenNotification: true,
          messageText: 'Current job added successfully',
          initialDatas: {
            company_name: "",
            position: "",
            min_salary: '',
            max_salary: '',
            clearance: "",
            priority_level: "",
            state: "",
            city: "",
            category_id: 1,
            account_id: "",
            description: "",
          }
        }, () => {
          setTimeout(() => {
            location.reload()
          }, 1000)
        })
        setTimeout(() => {
          this.setState({ errorOpenNotification: false })
        }, 2000);
      } else {
        //Check Error Response
        /* istanbul ignore next */
        this.setState({ isError: true, errorOpenNotification: true, messageText: responseJson?.meta?.message || 'Current job not added.' })
        setTimeout(() => {
          this.setState({ errorOpenNotification: false })
        }, 2000);
      }
    }

  }
  async componentDidMount() {
    this.getCategory();
    isLoggedIn(this.props.navigation)
  }
  getCategory() {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.contentTypeApiPostCreationPost,
    };

    const getAccount = apiCall({
      header: header,
      httpBody: {},
      url: `${configJSON.endPointCategory}?token=${token}`,
      httpMethod: configJSON.methodApiCategory,
    });
    this.getCategoryData = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  postCreatePost = (values: any) => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.contentTypeApiPostCreationPost, token: token
    };

    const attrs = {
      "company_name": values.company_name,
      "position": values.position,
      "clearance": values.clearance,
      "priority_level": values.priority_level,
      "category_id": String(values.category_id),
      "max_sal": `${values.max_salary}`,
      "min_sal": `${values.min_salary}`,
      "state": values.state,
      "city": values.city,
      "description": values.description,
    };

    const PostCreationData = {
      attributes: attrs,
    };

    const httpBody = {
      data: PostCreationData,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostCreationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointPostCreationPost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodApiPostCreationPost
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  // Customizable Area End

}
